import { IScanInterval } from '@models/api-get-scanned-interval';
import { IGetRestaurantProduct } from '@models/get-restaurant-product';
import { IRestaurantProduct } from '@models/restaurant-products';
import { IStateServers } from '@models/state-servers';
import { IUser } from '@models/user';
import { SCAN_DUE_DATE_DEFAULT, SCAN_QUALITY_DATE_DEFAULT } from '@utils/common';
import { convertSec } from '@utils/convert-sec';
import { DefaultQuery } from '@utils/default-query';
import { isOk } from '@utils/is-ok';
import {
  ACTIVATE_LOCATION,
  ACTIVATE_USER,
  CLEAR_GEOCODE,
  CLEAR_SUGGESTIONS_PLACES,
  DEACTIVATE_LOCATION,
  DELETE_BRAND,
  DELETE_CHAT_MESSAGE,
  DELETE_CHAT_ROOM,
  DELETE_COMMENT,
  DELETE_EDIT_LOCATION_IMAGES,
  DELETE_HISTORY_SUMMARY,
  DELETE_LOCATION,
  DELETE_LOCATION_IMAGE,
  DELETE_RESTAURANT_PRODUCT,
  DELETE_SERIAL,
  DELETE_USER,
  GEOCODE,
  GET_ACTIVITIES,
  GET_ADDITIONAL_ALERTS,
  GET_ADDITIONAL_NOTIFICATIONS,
  GET_ADDITIONAL_SERIALS,
  GET_ALERTS,
  GET_ALERTS_COUNT,
  GET_ALERTS_URGENT_COUNT,
  GET_ALERT_ANALYSIS,
  GET_ALL_BRANDS,
  GET_ALL_LOCATIONS,
  GET_ANALYSIS,
  GET_AUTH_USER,
  GET_BRANDS,
  GET_BRAND_LOCATIONS,
  GET_CHAT_MESSAGES,
  GET_CHAT_ROOMS,
  GET_CHAT_USERS,
  GET_COMMENTS,
  GET_DETAIL_VIEW_ALERT,
  GET_DETAIL_VIEW_SERIAL,
  GET_HISTORY_SUMMARIES,
  GET_INVENTORY,
  GET_LOCATIONS,
  GET_LOCATION_IMAGES,
  GET_LOCATION_REGIONS,
  GET_MASTER_BRANDS,
  GET_MASTER_LOCATIONS,
  GET_MASTER_USERS,
  GET_NEW_NOTIFICATION_COUNT,
  GET_NOTIFICATIONS,
  GET_OR_CREATE_CHAT_ROOM,
  GET_PLACE_AUTOCOMPLETE,
  GET_RESTAURANT_PRODUCTS,
  GET_RESTAURANT_PRODUCTS_BY_ID,
  GET_SCAN_INTERVAL_TIME,
  GET_SERIALS,
  GET_SERIAL_BRANDS,
  GET_SERIAL_BREW_LOCATIONS,
  GET_SERIAL_DIST_LOCATIONS,
  GET_SERIAL_REST_LOCATIONS,
  GET_SUPPLIERS,
  GET_TRANSACTION,
  GET_USERS,
  GET_USER_LOCATIONS,
  INITIALIZE_SERVERS,
  REGISTER_ALERT_DETAIL,
  REGISTER_ALERT_SERIAL,
  REGISTER_BRAND,
  REGISTER_CHAT_MESSAGE,
  REGISTER_CHAT_ROOM,
  REGISTER_COMMENT,
  REGISTER_HISTORY_SUMMARY,
  REGISTER_LOCATION,
  REGISTER_LOCATION_IMAGE,
  REGISTER_LOGIN_HISTORY,
  REGISTER_RESTAURANT_PRODUCT,
  REGISTER_SERIAL,
  REGISTER_TEMP_USER,
  REGISTER_USER,
  RESET_SERIAL,
  SEND_NOTIFICATION,
  SERVER_ADDITIONAL_GET_REQUEST,
  SERVER_GET_AUTH_USER_REQUEST,
  SERVER_GET_REQUEST,
  SERVER_REQUEST,
  SERVER_REQUEST_DONE,
  SET_ACTIVITIES_QUERY,
  SET_ALERTS_QUERY,
  SET_ALERT_FILTER_QUERY,
  SET_ANALYSIS_QUERY,
  SET_BRANDS_QUERY,
  SET_CHAT_MESSAGES_QUERY,
  SET_CHAT_ROOM,
  SET_CHAT_ROOMS_QUERY,
  SET_CHAT_USERS_QUERY,
  SET_COMMENTS_QUERY,
  SET_EDIT_LOCATION_IMAGES,
  SET_HISTORY_SUMMARIES_QUERY,
  SET_LOCATIONS_QUERY,
  SET_NOTIFICATION_QUERY,
  SET_RESTAURANT_PRODUCTS_QUERY,
  SET_SCAN_DUE_DATE,
  SET_SCAN_INTERVAL_TIME,
  SET_SCAN_QUALITY_DATE,
  SET_SERIALS_QUERY,
  SET_USERS_QUERY,
  UPDATE_ALERT,
} from '../actions';

export const initialQuery = {
  offset: 0,
  limit: 10,
  searchText: '',
  order: [['createdAt', 'DESC']], // DESC: 降順, ASC: 昇順
  where: {},
  totalCounts: 0,
};

const initializeState: IStateServers = {
  isRequesting: false,
  isGetRequesting: false,
  isAdditionalGetRequesting: false,
  isServerGetAuthUserRequesting: false,
  isLoginHistoryRegistered: false,
  errorCode: null,
  errorMessage: null,
  // Master: Serials
  serials: [],
  serialsQuery: {
    ...DefaultQuery,
  },
  notificationQuery: {
    ...DefaultQuery,
  },
  serialsTotalCounts: 0,
  serialBrands: [],
  serialBrandsTotalCounts: 0,
  serialBrewLocations: [],
  serialBrewLocationsTotalCounts: 0,
  serialDistLocations: [],
  serialDistLocationsTotalCounts: 0,
  serialRestLocations: [],
  serialRestLocationsTotalCounts: 0,
  //Master: Analysis
  analysisQuery: {
    ...DefaultQuery,
  },
  // Master: Locations
  locations: [],
  locationsQuery: {
    ...DefaultQuery,
  },
  locationsTotalCounts: 0,
  suppliers: [],
  suppliersTotalCounts: 0,
  // Master: Brands
  brands: [],
  brandsQuery: {
    ...DefaultQuery,
  },
  alertsQuery: {
    ...DefaultQuery,
  },
  brandsTotalCounts: 0,
  brandLocations: [],
  brandLocationsTotalCounts: 0,
  // Master: Users
  user: null,
  users: [],
  usersQuery: {
    ...DefaultQuery,
  },
  usersTotalCounts: 0,
  userLocations: [],
  userLocationsTotalCounts: 0,
  // Section: activities
  activities: [],
  activitiesQuery: {
    ...DefaultQuery,
  },
  activitiesTotalCounts: 0,
  // Section: comments
  comments: [],
  commentsQuery: {
    ...DefaultQuery,
  },
  commentsTotalCounts: 0,
  // Section: historySummaries
  historySummaries: [],
  historySummariesQuery: {
    ...DefaultQuery,
  },
  historySummariesTotalCounts: 0,
  // Section: restaurantProducts
  restaurantProducts: [],
  restaurantProductsQuery: {
    ...DefaultQuery,
  },
  restaurantProductsTotalCounts: 0,
  // Chat: users
  chatUsers: [],
  chatUsersQuery: {
    ...DefaultQuery,
    limit: 500,
  },
  chatUsersTotalCounts: 0,
  // Chat: allUserChats
  chatRoom: null,
  chatRooms: [],
  chatRoomsQuery: {
    ...DefaultQuery,
    limit: 500,
    order: [['updatedAt', 'DESC']],
  },
  chatRoomsTotalCounts: 0,
  // Chat: chatMessages
  chatMessages: [],
  chatMessagesQuery: {
    ...DefaultQuery,
    limit: 500,
  },
  chatMessagesTotalCounts: 0,
  // Section: Google map api
  suggestionsPlaces: [],
  suggestionsPlacesTotalCounts: 0,
  alerts: [],
  allBrands: null,
  allLocations: null,
  detailViewSerial: null,
  restaurantProductsById: {},
  targetLocationImages: [],
  editLocationImages: [],
  newNotificationsCount: 0,
  notifications: null,
  readNotification: [],
  alertsCount: null,
  filteredAlertsCount: 0,
  scanIntervals: {
    scanDueDate: SCAN_DUE_DATE_DEFAULT,
    scanQualityDate: SCAN_QUALITY_DATE_DEFAULT,
  },
  deletedLocationImages: [],
  locationRegions: null,
  alertFilterQuery: null,
  stocksQuery: {
    ...DefaultQuery,
  },
  analysis: [],
  alertAnalysis: [],
  transaction: [],
  inventory: [],
  alertsUrgentCount: {
    DELAY: 0,
    QUALITY: 0,
    GPS: 0,
  },
  geocodeResults: [],
};

export function servers(state: IStateServers = initializeState, action): IStateServers {
  const { result, errorCode, errorMessage, query, obj } = action;
  const data = result && result.data ? result.data : {};
  const response: IStateServers = {
    ...state,
    isRequesting: false,
    isGetRequesting: false,
    isAdditionalGetRequesting: false,
    isServerGetAuthUserRequesting: false,
    errorCode,
    errorMessage,
  };
  switch (action.type) {
    case INITIALIZE_SERVERS:
      return {
        ...initializeState,
      };
    case SERVER_REQUEST:
      return {
        ...response,
        isRequesting: true,
      };
    case SERVER_GET_REQUEST:
      return {
        ...response,
        isGetRequesting: true,
      };
    case SERVER_ADDITIONAL_GET_REQUEST:
      return {
        ...response,
        isAdditionalGetRequesting: true,
      };
    case SERVER_GET_AUTH_USER_REQUEST:
      return {
        ...response,
        isServerGetAuthUserRequesting: true,
      };
    /* Update */
    case SERVER_REQUEST_DONE:
    case REGISTER_SERIAL:
    case UPDATE_ALERT:
    case REGISTER_ALERT_SERIAL:
    case SEND_NOTIFICATION:
    case REGISTER_ALERT_DETAIL:
    case DELETE_SERIAL:
    case RESET_SERIAL:
    case ACTIVATE_LOCATION:
    case DEACTIVATE_LOCATION:
    case REGISTER_LOCATION:

    case DELETE_LOCATION_IMAGE:
    case DELETE_LOCATION:
    case REGISTER_BRAND:
    case DELETE_BRAND:
    case REGISTER_USER:
    case REGISTER_TEMP_USER:
    case ACTIVATE_USER:
    case DELETE_USER:
    case REGISTER_CHAT_ROOM:
    case DELETE_CHAT_ROOM:
    case REGISTER_CHAT_MESSAGE:
    case DELETE_CHAT_MESSAGE:
    case REGISTER_COMMENT:
    case DELETE_COMMENT:
    case REGISTER_HISTORY_SUMMARY:
    case DELETE_HISTORY_SUMMARY:
    case REGISTER_RESTAURANT_PRODUCT:
    case DELETE_RESTAURANT_PRODUCT:
    case SET_SCAN_INTERVAL_TIME:
      break;
    case SET_CHAT_ROOM:
      return {
        ...response,
        chatRoom: obj,
      };
    case REGISTER_LOCATION_IMAGE:
      return {
        ...response,
        editLocationImages: [],
        deletedLocationImages: [],
      };
    /* Get */
    case GET_ALERTS:
      return {
        ...response,
        alerts: data?.objects,
        filteredAlertsCount: data?.totalCounts,
      };
    case GET_ALERTS_COUNT:
      return {
        ...response,
        alertsCount: data?.objects,
      };
    case GET_ALERTS_URGENT_COUNT:
      return {
        ...response,
        alertsUrgentCount: data?.objects,
      };
    case GET_SCAN_INTERVAL_TIME:
      const scanInterval: IScanInterval = result?.data;
      const scanDueDate = convertSec(scanInterval?.scanDueDate, 'hours');
      const scanQualityDate = convertSec(scanInterval?.scanQualityDate, 'days');
      return {
        ...response,
        scanIntervals: {
          scanDueDate,
          scanQualityDate,
        },
      };
    case GET_SERIALS:
      return {
        ...response,
        serials: data?.objects,
        serialsTotalCounts: data?.totalCounts,
      };
    case GET_ADDITIONAL_SERIALS:
      return {
        ...response,
        serials: response.serials.concat(data?.objects),
        serialsTotalCounts: response.serialsTotalCounts + data?.totalCounts,
      };
    case GET_ADDITIONAL_ALERTS:
      return {
        ...response,
        alerts: response.alerts.concat(data?.objects),
      };
    case GET_DETAIL_VIEW_SERIAL:
      const detailViewSerial = isOk(data?.objects) && data?.objects[0];
      return {
        ...response,
        detailViewSerial,
      };
    case GET_DETAIL_VIEW_ALERT:
      const detailViewAlert = isOk(data?.objects) && data?.objects[0];
      return {
        ...response,
        detailViewAlert,
      };
    case GET_SERIAL_BRANDS:
      return {
        ...response,
        serialBrands: data?.objects,
        serialBrandsTotalCounts: data?.totalCounts,
      };
    case GET_SERIAL_BREW_LOCATIONS:
      return {
        ...response,
        serialBrewLocations: data?.objects,
        serialBrewLocationsTotalCounts: data?.totalCounts,
      };
    case GET_SERIAL_DIST_LOCATIONS:
      return {
        ...response,
        serialDistLocations: data?.objects,
        serialDistLocationsTotalCounts: data?.totalCounts,
      };
    case GET_SERIAL_REST_LOCATIONS:
      return {
        ...response,
        serialRestLocations: data?.objects,
        serialRestLocationsTotalCounts: data?.totalCounts,
      };
    case GET_LOCATIONS:
      return {
        ...response,
        locations: data?.objects,
        locationsTotalCounts: data?.totalCounts,
      };
    case GET_MASTER_LOCATIONS:
      return {
        ...response,
        locations: data?.objects,
        locationsTotalCounts: data?.totalCounts,
      };
    case GET_LOCATION_IMAGES:
      return {
        ...response,
        editLocationImages: data?.objects,
        targetLocationImages: data?.objects,
      };
    case SET_SCAN_DUE_DATE:
      return {
        ...state,
        scanIntervals: {
          scanDueDate: action.value,
          scanQualityDate: state.scanIntervals.scanQualityDate,
        },
      };
    case SET_SCAN_QUALITY_DATE:
      return {
        ...state,
        scanIntervals: {
          scanDueDate: state.scanIntervals.scanDueDate,
          scanQualityDate: action.value,
        },
      };
    case GET_ALL_LOCATIONS:
      return {
        ...response,
        allLocations: data?.objects,
      };
    case GET_LOCATION_REGIONS:
      let regions = [];
      data?.objects.forEach((r: any) => {
        if (r) {
          regions.push(r.country);
        }
      });
      return {
        ...response,
        locationRegions: regions,
      };
    case GET_SUPPLIERS:
      return {
        ...response,
        suppliers: data?.objects,
        suppliersTotalCounts: data?.totalCounts,
      };
    case GET_BRANDS:
      return {
        ...response,
        brands: data?.objects,
        brandsTotalCounts: data?.totalCounts,
      };
    case GET_MASTER_BRANDS:
      return {
        ...response,
        brands: data?.objects,
        brandsTotalCounts: data?.totalCounts,
      };
    case GET_ALL_BRANDS:
      return {
        ...response,
        allBrands: data?.objects,
      };
    case GET_BRAND_LOCATIONS:
      return {
        ...response,
        brandLocations: data?.objects,
        brandLocationsTotalCounts: data?.totalCounts,
      };
    case GET_AUTH_USER:
      const userData: IUser = data || {};
      return {
        ...response,
        user: userData,
      };
    case GET_USERS:
      return {
        ...response,
        users: data?.objects,
        usersTotalCounts: data?.totalCounts,
      };
    case GET_MASTER_USERS:
      return {
        ...response,
        users: data?.objects,
        usersTotalCounts: data?.totalCounts,
      };
    case GET_USER_LOCATIONS:
      return {
        ...response,
        userLocations: data?.objects,
        userLocationsTotalCounts: data?.totalCounts,
      };
    case GET_ACTIVITIES:
      return {
        ...response,
        activities: data?.objects,
        activitiesTotalCounts: data?.totalCounts,
      };
    case GET_COMMENTS:
      return {
        ...response,
        comments: data?.objects,
        commentsTotalCounts: data?.totalCounts,
      };
    case GET_HISTORY_SUMMARIES:
      return {
        ...response,
        historySummaries: data?.objects,
        historySummariesTotalCounts: data?.totalCounts,
      };
    case GET_RESTAURANT_PRODUCTS:
      return {
        ...response,
        restaurantProducts: data?.objects,
        restaurantProductsTotalCounts: data?.totalCounts,
      };
    case GET_RESTAURANT_PRODUCTS_BY_ID:
      const getRestaurantProductsResult: IGetRestaurantProduct[] = data?.objects || [];
      // normalize
      const gestaurantProducts: IRestaurantProduct[] = getRestaurantProductsResult.map(
        (product) => ({
          brandId: product.brandId,
          brandName: product.brand.name,
          inventory: product.stock,
          temp: product.temp,
          lastDeliveryDate: null, // TODO [SAKE-BC]
          menuPrice: product.jpPrice, // TODO [SAKE-BC]
          menuUnit: product.salesForm, // TODO [SAKE-BC]
          sales: 0, // TODO [SAKE-BC]
        }),
      );
      const restObj = isOk(getRestaurantProductsResult) &&
        getRestaurantProductsResult[0].restLocId && {
          [getRestaurantProductsResult[0].restLocId]: gestaurantProducts,
        };

      return {
        ...response,
        restaurantProductsById: {
          ...state.restaurantProductsById,
          ...restObj,
        },
      };
    case GET_CHAT_USERS:
      return {
        ...response,
        chatUsers: data?.objects,
        chatUsersTotalCounts: data?.totalCounts,
      };
    case GET_CHAT_MESSAGES:
      return {
        ...response,
        chatMessages: data?.objects,
        chatMessagesTotalCounts: data?.totalCounts,
      };
    case GET_CHAT_ROOMS:
      return {
        ...response,
        chatRooms: data?.objects,
        chatRoomsTotalCounts: data?.totalCounts,
      };
    case GET_OR_CREATE_CHAT_ROOM:
      return {
        ...response,
        chatRoom: data,
      };
    case GET_PLACE_AUTOCOMPLETE:
      return {
        ...response,
        suggestionsPlaces: data?.objects,
        suggestionsPlacesTotalCounts: data?.totalCounts,
      };
    case SET_EDIT_LOCATION_IMAGES:
      return {
        ...state,
        editLocationImages: state.editLocationImages.concat(action.obj),
      };
    // Sreenath-11Nov21- Location image delete action
    case DELETE_EDIT_LOCATION_IMAGES:
      let deletedImages = action.obj;
      let activeImages = [];
      state.editLocationImages.forEach((image) => {
        let imageIndex = deletedImages.findIndex((x) => x.id === image.id);
        if (imageIndex < 0) {
          // -1 then the image is not deleted;
          activeImages.push(image);
        }
        deletedImages.forEach((item) => {
          !image.id && item.url !== image.url && activeImages.push(image);
        });
        // if (!image.id && deletedImages[0].url !== image.url) {
        //   activeImages.push(image);
        // }
      });
      let deletedLocationImages = state.deletedLocationImages.concat(action.obj);
      return {
        ...state,
        editLocationImages: activeImages,
        deletedLocationImages: deletedLocationImages.filter((x) => x.id),
      };
    /* Set Query */
    case SET_SERIALS_QUERY:
      return {
        ...response,
        serialsQuery: query,
      };
    case SET_NOTIFICATION_QUERY:
      return {
        ...response,
        notificationQuery: query,
      };
    case SET_ALERTS_QUERY:
      return {
        ...response,
        alertsQuery: query,
      };
    case SET_LOCATIONS_QUERY:
      return {
        ...response,
        locationsQuery: query,
      };
    case SET_ANALYSIS_QUERY:
      return {
        ...response,
        locationsQuery: query,
      };
    case SET_BRANDS_QUERY:
      return {
        ...response,
        brandsQuery: query,
      };
    case SET_USERS_QUERY:
      return {
        ...response,
        usersQuery: query,
      };
    case SET_CHAT_USERS_QUERY:
      return {
        ...response,
        chatUsersQuery: query,
      };
    case SET_CHAT_MESSAGES_QUERY:
      return {
        ...response,
        chatMessagesQuery: query,
      };
    case SET_CHAT_ROOMS_QUERY:
      return {
        ...response,
        chatRoomsQuery: query,
      };
    case SET_ACTIVITIES_QUERY:
      return {
        ...response,
        activitiesQuery: query,
      };
    case SET_COMMENTS_QUERY:
      return {
        ...response,
        commentsQuery: query,
      };
    case SET_HISTORY_SUMMARIES_QUERY:
      return {
        ...response,
        historySummariesQuery: query,
      };
    case SET_RESTAURANT_PRODUCTS_QUERY:
      return {
        ...response,
        restaurantProductsQuery: query,
      };
    case CLEAR_SUGGESTIONS_PLACES:
      return {
        ...response,
        suggestionsPlaces: [],
        suggestionsPlacesTotalCounts: 0,
      };
    case GET_NOTIFICATIONS:
      return {
        ...response,
        notifications: action.result,
      };
    case GET_ADDITIONAL_NOTIFICATIONS:
      return {
        ...response,
        notifications: response.notifications.concat(action.result),
      };
    case GET_NEW_NOTIFICATION_COUNT:
      return {
        ...response,
        newNotificationsCount: action.result,
      };
    case SET_ALERT_FILTER_QUERY:
      return {
        ...response,
        alertFilterQuery: query,
      };
    case REGISTER_LOGIN_HISTORY:
      return {
        ...response,
        isLoginHistoryRegistered: true,
      };
    case GET_ANALYSIS:
      return {
        ...response,
        analysis: data?.objects,
      };
    case GET_ALERT_ANALYSIS:
      return {
        ...response,
        alertAnalysis: data?.objects,
      };
    case GET_TRANSACTION:
      return {
        ...response,
        transaction: data?.objects,
      };
    case GET_INVENTORY:
      return {
        ...response,
        inventory: data?.objects,
      };
    case GEOCODE:
      return {
        ...response,
        geocodeResults: data?.results || [],
      };
    case CLEAR_GEOCODE:
      return {
        ...response,
        geocodeResults: [],
      };
    default:
      break;
  }
  return response;
}
