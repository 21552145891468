import { CircularLoading } from '@container/components/circular-loading';
import { i18n } from '@i18n/lang';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Paper,
  StyleRules,
  TextField,
  Theme,
  Toolbar,
  withStyles,
} from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';
import { EAlertStatus } from '@models/alert-status-type';
import { EAlert } from '@models/alert-type';
import { AllPages, ListPages } from '@models/all-pages';
import { IUpdateAlertRequest } from '@models/api-update-alert';
import { IBrand } from '@models/brand';
import { TBrandLocPopupOpener } from '@models/brand-loc-popup-opener';
import { ECategoryType } from '@models/category-type';
import { ILocation } from '@models/location';
import { EQROperations } from '@models/qr-operations';
import { IQuery } from '@models/query';
import { ENotificationType } from '@models/send-notification-type';
import { ISerial } from '@models/serial';
import { IStateApps } from '@models/state-apps';
import { IStateAuth } from '@models/state-auth';
import { IStateServers } from '@models/state-servers';
import { TSupportedLanguages } from '@models/supported-languages';
import { BrandEditDialog } from '@old-components/brands/config/brand-edit-dialog';
// Sreenath -19Nov21- commenting the LocationEditDialog component which is not being used in this page
// import { openBrandEditDialog, openLocationEditDialog, setCurrentPage, setEditBrand, setEditLocation } from '@old-components/locations/config/location-edit-dialog';
import {
  openBrandEditDialog,
  openLocationEditDialog,
  setCurrentPage,
  setEditBrand,
  setEditLocation,
} from '@redux/actions/appsActions';
import {
  getDetailViewAlert,
  getDetailViewSerial,
  getMasterLocations,
  registerAlertDetail,
  registerAlertSerial,
  registerLocation,
  registerSerials,
  registerSerialsCancelShipping,
  sendNotification,
  updateAlert,
} from '@redux/actions/serversActions';
import { IStoreState } from '@redux/reducers';
import appLanguages from '@utils/app-languages';
import { AppRoutePaths } from '@utils/app-route-paths';
import { BAIDU_ONLY_COUNTRIES, SERIAL_ACTION } from '@utils/common';
import { DISTRIBUTION_DETAILS_LOCATION_LIST_LIMIT } from '@utils/config';
import { getCountryName } from '@utils/country-info/get-country-name';
import { functions } from '@utils/firebase';
import { formatDate } from '@utils/format-date';
import { getAlertMessage } from '@utils/get-alert-message';
import { getAlertStatusName } from '@utils/get-alert-status-name';
import { getAlertStatusPill } from '@utils/get-alert-status-pill';
import { getAlertStatusText } from '@utils/get-alert-status-text';
import { getDateDuration } from '@utils/get-date-duration';
import { getUserCategory, isUserAdmin, isUserBreweryOrAdmin } from '@utils/get-user-category';
import { isOk } from '@utils/is-ok';
import { AlertResolutionPopup } from '@visual/alert-resolution-popup';
import { BrandPopup } from '@visual/brand-popup';
import { Button } from '@visual/button';
import { ColoredBackground } from '@visual/colored-background';
import {
  DistributionAlertSummary,
  Props as DistributionAlertSummaryProps,
} from '@visual/distribution-alert-summary';
import { DistributionCard, Props as DistributionCardProps } from '@visual/distribution-card';
import {
  DistributionDetail as DistributionDetailVisual,
  Props as DistributionDetailProps,
} from '@visual/distribution-detail';
import { DistributionDetailImage } from '@visual/distribution-detail-image';
import { FloatingArea } from '@visual/floating-area';
import { Icon } from '@visual/icon';
import { ListPageItem } from '@visual/list-page-item';
import { Logo } from '@visual/logo';
import { MapView, MapViewCoordinate } from '@visual/map-view';
import { Item as SelectBoxItem } from '@visual/select-box';
import { SerialInformationEditPopup } from '@visual/serial-information-edit-popup';
import { TextDisplay } from '@visual/text-display';
import { UpdateHistory, UpdateHistoryPopup } from '@visual/update-history-popup';
import { History, LocationState } from 'history';
import hash from 'object-hash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import userEnv from 'userEnv';
import { getAlertCategory } from './utils/get-alert-category';
import { getAlertResolutionButtonProps } from './utils/get-alert-resolution-button-props';
import { getAlertRevertButtonProps } from './utils/get-alert-revert-button-props';
import { getAvailableAlertResolutions } from './utils/get-available-alert-resolutions';
import { getCurrentLocations } from './utils/get-current-locations';
import { getNextStatus } from './utils/get-next-status';
import { getPreviousStatus } from './utils/get-previous-status';
import { getUserSuffix } from './utils/get-user-suffix';

class DistributionDetailClass extends React.PureComponent<Props, State> {
  private AlertLocation: ILocation;
  private RangeIterator = 100;
  private RangeLimit = 3000;
  private locationList = [];

  public static defaultProps: Partial<Props> = {
    caller: 'distribution-history',
    showAlerts: false,
  };

  constructor(props) {
    super(props);

    const {
      apps: { currentLanguage: lang },
      showAlerts,
    } = this.props;

    const targetSerialId = Number(props?.match?.params?.serialId);
    const targetAlertId = showAlerts && Number(props?.match?.params?.alertId);

    this.state = {
      lang,
      targetSerialId,
      targetAlertId,
      showBrandPopup: false,
      showUpdateHistoryPopup: false,
      showAlertResolution: false,
      showAlertRevert: false,
      showSerialInformationEdit: false,
      showSetToUnprocessable: false,
      showMapPopup: false,
      message: '',
      isMobile: window.innerWidth < 600,
    };
  }

  public async componentDidMount() {
    const {
      getDetailViewSerial,
      getDetailViewAlert,
      setCurrentPage,
      getMasterLocations,
      servers: { locations },
    } = this.props;
    const { targetSerialId, targetAlertId } = this.state;
    if (targetSerialId) await getDetailViewSerial(targetSerialId);
    if (targetAlertId) await getDetailViewAlert(targetAlertId);
    setCurrentPage('distribution-detail');
    if (!isOk(locations)) {
      await getMasterLocations({});
    }

    try {
      const request = functions.httpsCallable('getMasterLocations');
      const query = {
        limit: DISTRIBUTION_DETAILS_LOCATION_LIST_LIMIT,
      };
      const response = await request(query);
      this.locationList = response?.data?.objects;
    } catch (response) {
      console.log('error:', response);
    }
  }

  handleClickSendMessageButton() {
    const {
      servers: { detailViewAlert: alertInfo },
    } = this.props;
    const { message } = this.state;
    this.executeRegisterAlertDetail(alertInfo?.status, message);
    setTimeout(() => this.setState({ message: '' }), 100);
  }

  public render(): JSX.Element {
    const {
      servers: {
        isRequesting,
        isGetRequesting,
        isAdditionalGetRequesting,
        isServerGetAuthUserRequesting,
        allLocations,
        user,
        allBrands,
      },
      auth,
    } = this.props;
    const requestInProgress =
      !isOk(user) ||
      !allBrands ||
      !allLocations ||
      isRequesting ||
      isGetRequesting ||
      isAdditionalGetRequesting ||
      auth.isRequesting ||
      isServerGetAuthUserRequesting;
    return (
      <ColoredBackground>
        <div className={this.props.classes.toolbar} />
        {requestInProgress ? <CircularLoading /> : this.getContents()}
      </ColoredBackground>
    );
  }

  protected getImageHeader(): JSX.Element {
    const {
      apps: { currentLanguage: lang },
      servers: { detailViewSerial: targetSerial },
    } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          backgroundColor: 'white',
        }}
      >
        {this.getBrandImage()}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <div>
            <TextDisplay size='x-small' color='dark2'>
              Brand
            </TextDisplay>
            <TextDisplay weight='bold' color='dark2'>
              {targetSerial?.brand?.name}
            </TextDisplay>
          </div>
          <div>
            <TextDisplay size='x-small' color='dark2'>
              {targetSerial?.brewLoc?.name}
            </TextDisplay>
            <TextDisplay weight='bold' color='dark2'>
              {`No.${targetSerial?.code}`}
            </TextDisplay>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}
        >
          {this.getAlertResolutionButton()}
        </div>
      </div>
    );
  }

  protected getContents(): JSX.Element {
    const { showAlerts } = this.props;
    return (
      <>
        {this.getImageHeader()}
        {showAlerts ? this.getAlertSummary() : this.getDistributionSummary()}
        {this.getTitle()}
        <div className={this.props.classes.container}>
          {this.getBrandPopup()}
          {/* Duval -06Dec21- Create revert button for alert*/}
          {this.getAlertResolutionPopup()}
          {/*this.getAlertRevertPopup()*/}
          {this.getSetToUnprocessableResolutionPopup()}
          {this.getUpdateHistoryPopup()}
          <BrandEditDialog />
          {/*
          Sreenath -19Nov21- commenting the LocationEditDialog component which is not being used in this page
          <LocationEditDialog />
          */}
          {this.getDistributionInfo()}
          {this.getDistributionMap()}
          {this.getUpdateHistory()}
          {/* {this.getAlertResolutionButtons()} */}
          {this.getSerialInformationEditPopup()}
        </div>
        {this.getFloatingArea()}
      </>
    );
  }

  protected getUpdateHistoryPopup(): JSX.Element {
    const { showUpdateHistoryPopup } = this.state;
    const {
      showAlerts,
      apps: { currentLanguage: lang },
      servers: { detailViewAlert },
    } = this.props;
    if (!showAlerts || !isOk(detailViewAlert?.alertDetails)) return;
    const updateHistoryList: UpdateHistory[] = detailViewAlert?.alertDetails?.map(
      (updateHistory) => {
        const { updatedAt, assigneeName, status } = updateHistory;
        const title = [
          getAlertStatusText(getPreviousStatus(status), lang),
          getAlertStatusText(status, lang),
        ]
          .filter((data) => data)
          .join(' > ');
        return {
          title,
          updateDate: formatDate(updatedAt),
          assigneeName: assigneeName,
          userTag: getUserSuffix(status, lang),
        };
      },
    );
    return (
      <UpdateHistoryPopup
        updateHistoryList={updateHistoryList}
        showPopup={showUpdateHistoryPopup}
        onClose={() => this.toggleUpdateHistoryPopup()}
      />
    );
  }

  protected getUpdateHistory(): JSX.Element {
    const {
      apps: { currentLanguage: lang },
      servers,
      servers: { detailViewAlert },
      classes,
    } = this.props;
    const { message, isMobile } = this.state;

    const sendMessageView = (
      <>
        {/*<AppBar color='default'>
        className={classes.bottomAppBar} */}
        <Toolbar className={classes.bottomAppBar}>
          <div className={classes.bottomGrow} />
          <Paper className={classes.drawerPaper} />
          <TextField
            color='primary'
            label=''
            placeholder={
              isMobile
                ? appLanguages.enterMessageForMobile[lang]
                : appLanguages.enterMessageForPC[lang]
            }
            fullWidth
            multiline
            rowsMax={2}
            value={message}
            onChange={(e) => this.setState({ message: e.target.value })}
            variant='outlined'
            onKeyPress={(e) => {
              if (e.key === 'Enter' && e.shiftKey) {
                this.handleClickSendMessageButton();
              }
            }}
          />
          <IconButton
            disabled={!message}
            edge='end'
            onClick={() => this.handleClickSendMessageButton()}
          >
            <SendIcon color={message ? 'primary' : 'disabled'} fontSize='large' />
          </IconButton>
        </Toolbar>
        {/*</AppBar>*/}
      </>
    );

    //if (!showAlerts || !isOk(detailViewAlert?.alertDetails)) return;
    return (
      <div className={classes.updateHistory}>
        <TextDisplay size='small'>{i18n.processingHistory[lang]}</TextDisplay>
        <div>
          {detailViewAlert?.alertDetails ? (
            <table>
              {detailViewAlert.alertDetails
                .filter((item, index, self) => {
                  return (
                    index ===
                    self.findIndex((obj) => {
                      const createdAtDiff =
                        Math.abs(
                          new Date(obj.createdAt).getTime() - new Date(item.createdAt).getTime(),
                        ) / 1000;
                      return createdAtDiff <= 30 && obj.assigneeName === item.assigneeName;
                    })
                  );
                })
                .map((detail, i) => {
                  const { updatedAt, user, location } = detail;
                  let assigneeName = detail?.assigneeName;
                  if (detail?.assigneeName === 'Sales recorded by Admin')
                    assigneeName = i18n.adminSaleRecord[lang];
                  else if (detail?.assigneeName === 'Scanned by the user')
                    assigneeName = i18n.scanByUser[lang];
                  /*const logMessage = [
                updatedAt && `${formatDate(updatedAt)}`,
                (detail?.assigneeName || detail?.location?.name) &&
                  `${getUserSuffix(status, lang)}：${[detail?.location?.name, assigneeName]
                    .filter((name) => name)
                    .join('　')}`,
              ]
                .filter((log) => log)
                .join('　');*/
                  if (assigneeName) {
                    return (
                      <tr key={i}>
                        <td className={classes.avatarCommentTable}>
                          <Avatar src={user ? user.imageUrl : ''} />
                        </td>
                        <td className={classes.nameCommentTable}>
                          <TextDisplay>
                            {user?.name || 'admin'}
                            {location?.name ? '@' + location?.name : ''}
                          </TextDisplay>
                          <TextDisplay size='small' key={hash(i)}>
                            {new Date(updatedAt).toLocaleString()}
                          </TextDisplay>
                        </td>
                        <td className={classes.commentCommentTable}>
                          <TextDisplay>{detail?.assigneeName}</TextDisplay>
                        </td>
                      </tr>
                    );
                  } else return;
                })}
              <tr></tr>
            </table>
          ) : (
            ''
          )}
        </div>
        <div className={classes.commentTable}>
          <Grid container>
            <Grid item className={classes.bottomChatBar}>
              <Grid container wrap='nowrap' spacing={2}>
                <Grid item>
                  <Avatar src={servers.user ? servers.user.imageUrl : ''} />
                </Grid>
                <Grid item xs={12}>
                  {sendMessageView}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2} />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  protected getAlertResolutionButton(): JSX.Element {
    const {
      showAlerts,
      apps: { currentLanguage: lang },
      servers: { detailViewAlert: alertInfo, user }, //, detailViewSerial: serialInfo
    } = this.props;

    if (
      !showAlerts ||
      !alertInfo?.status ||
      // alertInfo?.status === EAlertStatus.PROCESSED ||
      alertInfo?.status === EAlertStatus.APPROVED //||
      // alertInfo?.status === EAlertStatus.UNPROCESSABLE
    ) {
      return;
    }

    // const nextAlertStatus = getNextStatus(alertInfo?.status);
    // const userCategory = getUserCategory(user);

    let displayResolutionButton: boolean = false;
    //const alertType = alertInfo?.type;
    // Duval 1Feb22- show the change status to approved button for distributor only when the conditions are met
    // let restRecv: boolean = false;
    // let restSold: boolean = false;
    // if (serialInfo.restRecvAt) restRecv = true;
    // if (serialInfo.restSoldAt) restSold = true;

    if (
      getUserCategory(user) === ECategoryType.DISTRIBUTOR &&
      alertInfo.locType === ECategoryType.RESTAURANT &&
      alertInfo.type === EAlert.QUALITY
    )
      return;

    if (
      alertInfo?.type === EAlert.ILLEGAL_ROUTE ||
      alertInfo?.type === EAlert.ILLEGAL_SELLING ||
      alertInfo?.type === EAlert.ILLEGAL_SHIPPING
    )
      return;

    switch (alertInfo?.status) {
      case EAlertStatus.UNPROCESSED:
        // If next status is 対応中 then check available actions
        // const {
        //   showChat,
        //   showRangeEdit,
        //   showScanApp,
        //   showShippingDestEdit,
        // } = getAvailableAlertResolutions(userCategory, serialInfo, alertInfo);
        // displayResolutionButton = showChat || showRangeEdit || showScanApp || showShippingDestEdit;
        // Restaurant Users can change the status only when Scan Dlay & Inventory Quality Alert
        displayResolutionButton = true;
        // (!isUserRestaurant(user) &&
        //   (showChat || showRangeEdit || showScanApp || showShippingDestEdit)) ||
        // isUserRestaurant(user) ||
        // isUserBreweryOrAdmin(user);
        break;
      case EAlertStatus.PROCESSED:
      case EAlertStatus.UNPROCESSABLE:
        /*if (!isUserRestaurant(user)) {
          displayResolutionButton = true;
        } else if (isUserRestaurant(user)) {
          displayResolutionButton =
          alertType == EAlert.ILLEGAL_SELLING ||
          alertType == EAlert.ILLEGAL_SHIPPING ||
          alertType == EAlert.ILLEGAL_ROUTE;
        }*/
        displayResolutionButton = isUserBreweryOrAdmin(user);
        break;

      // case EAlertStatus.APPROVED:
      //   // If next status is 承認済, only Admin, Brewery, and Pattern A Distributor Users can display
      //   // Brew -> Dist -> Rest
      //   // Sreenath 24Nov21- show the change status to approved button for admin/brewery for all alerts
      //   // Show change status to approved button to distributors if the alerts are from restaurants.
      //   // Duval 1Feb22- show the change status to approved button for distributor only when the conditions are met
      //   displayResolutionButton =
      //     isUserBreweryOrAdmin(user) ||
      //     (alertInfo.locType === ECategoryType.RESTAURANT &&
      //       userCategory === ECategoryType.DISTRIBUTOR &&
      //       ((alertInfo.type === 1 && restRecv) || (alertInfo.type === 3 && restSold)));
      //   break;
      default:
        displayResolutionButton = false;
        break;
    }
    if (
      getUserCategory(user) === ECategoryType.RESTAURANT &&
      alertInfo?.status === EAlertStatus.UNPROCESSED
    )
      displayResolutionButton = true;

    if (!displayResolutionButton) return;

    let processClick;
    if (
      (alertInfo?.status === EAlertStatus.PROCESSED ||
        alertInfo?.status === EAlertStatus.UNPROCESSABLE) &&
      isUserBreweryOrAdmin(user) &&
      (alertInfo?.type === EAlert.QUALITY || alertInfo?.type === EAlert.SCAN_DATE)
      // (alertInfo?.type === EAlert.ILLEGAL_ROUTE ||
      //   alertInfo?.type === EAlert.ILLEGAL_SELLING ||
      //   alertInfo?.type === EAlert.ILLEGAL_SHIPPING)
    ) {
      processClick = () => this.handleAlertResolutionRegistrationClick();
    } else {
      processClick = () => this.toggleSerialInformationEditPopup();
    }
    if (
      (alertInfo?.status === EAlertStatus.PROCESSED ||
        alertInfo?.status === EAlertStatus.UNPROCESSABLE) &&
      isUserBreweryOrAdmin(user) &&
      (alertInfo?.type === EAlert.QUALITY || alertInfo?.type === EAlert.SCAN_DATE)
    ) {
      return (
        <Button
          {...getAlertResolutionButtonProps(alertInfo?.status, lang)}
          onClick={processClick}
        />
      );
    } else
      return (
        <Button
          {...getAlertResolutionButtonProps(alertInfo?.status, lang)}
          onClick={processClick}
        />
      );
  }

  protected getAlertUnresovedButton(): JSX.Element {
    const {
      apps: { currentLanguage: lang },
      servers: { detailViewAlert: alertInfo, detailViewSerial: targetSerial },
    } = this.props;

    let restSold: boolean = false;
    if (targetSerial) {
      if (targetSerial.restSoldAt) restSold = true;
    }

    let processClick;
    if (isUserAdmin && alertInfo?.type === EAlert.QUALITY && !restSold) {
      processClick = () => this.toggleSetToUnprocessablePopup();
    } else {
      processClick = () => this.skipToUnprocessableRegistrationClick();
    }
    return (
      <Button
        {...getAlertResolutionButtonProps(EAlertStatus.UNPROCESSABLE, lang)}
        onClick={processClick}
      />
    );
  }

  // Duval -06Dec21- Create revert button for alert
  protected getAlertRevertButton(): JSX.Element {
    const {
      apps: { currentLanguage: lang },
      servers: { detailViewAlert: alertInfo, user, detailViewSerial: serialInfo },
    } = this.props;

    if (!isUserAdmin(user) || alertInfo?.status === EAlertStatus.UNPROCESSED) {
      return;
    }

    // Duval -07Dec21- Create revert button for alert v2
    let displayRevertButton: boolean = true;
    const alertType = alertInfo?.type;

    switch (alertType) {
      case EAlert.DIST_QUALITY:
        if (serialInfo.distShipAt) {
          displayRevertButton = false;
          //console.log('DIST_QUALITY');
        }
        break;
      case EAlert.QUALITY:
        if (serialInfo.restSoldAt) {
          displayRevertButton = false;
          //console.log('REST_QUALITY');
        }
        break;
      case EAlert.SCAN_DATE:
        if (
          (alertInfo.locType === 2 && serialInfo.distRecvAt) ||
          (alertInfo.locType === 3 && serialInfo.restRecvAt)
        ) {
          displayRevertButton = false;
          //console.log('DELAY');
        }
        break;
      default:
        displayRevertButton = true;
        //console.log('No problem');
        break;
    }
    //console.log('displayRevertButton :', displayRevertButton);
    if (!displayRevertButton) {
      //console.log('Not displaying');
      return;
    }
    //console.log('Displaying');
    return (
      <Button
        {...getAlertRevertButtonProps(alertInfo?.status, lang)}
        onClick={() => this.handleAlertRevertRegistrationClick()}
      />
    );
  }

  protected getAlertResolutionButtons(): JSX.Element {
    const {
      showAlerts,
      classes,
      servers: { detailViewAlert: alertInfo, user },
    } = this.props;

    if (
      !showAlerts ||
      !alertInfo?.status ||
      alertInfo?.status === EAlertStatus.APPROVED ||
      alertInfo?.status === EAlertStatus.UNPROCESSABLE
    ) {
      return <div className={classes.alertResolutionButtons}>{this.getAlertRevertButton()}</div>;
    }
    return (
      <div className={classes.alertResolutionButtons}>
        {this.getAlertResolutionButton()}
        {/* Duval -06Dec21- Create revert button for alert*/}
        <div className={classes.alertResolutionButtons}>{this.getAlertRevertButton()}</div>
        {isUserBreweryOrAdmin(user) && (
          <div className={classes.setToUnprocessableButton}>
            {
              this.getAlertUnresovedButton()
              /*<Button
              {...getAlertResolutionButtonProps(EAlertStatus.UNPROCESSABLE, lang)}
              onClick={() => this.toggleSetToUnprocessablePopup()}
            />*/
            }
          </div>
        )}
      </div>
    );
  }

  protected getBrandImage(): JSX.Element {
    const {
      servers: { detailViewSerial: targetSerial, detailViewAlert: alertInfo },
      apps: { currentLanguage: lang },
    } = this.props;

    return (
      <DistributionDetailImage
        imgUrl={targetSerial?.brand?.imageUrl}
        alertStatusPill={getAlertStatusPill(alertInfo?.status, lang, 'big-pill')}
        onBrandBottleClick={() => this.toggleBrandPopup()}
      />
    );
  }

  protected getFloatingArea(): JSX.Element {
    const { classes, servers } = this.props;
    const hideMapIcon = BAIDU_ONLY_COUNTRIES.includes(servers?.user?.location?.country);
    return (
      <FloatingArea>
        <div className={classes.chatIcon}>
          <Logo type='fab-chat' onClick={() => this.gotoChatPage()} />
        </div>
        {!hideMapIcon && <Logo type='fab-map' onClick={() => this.toggleMapPopup()} />}
      </FloatingArea>
    );
  }

  protected getBrandPopup(): JSX.Element {
    const { showBrandPopup } = this.state;
    const {
      apps: { currentLanguage: lang },
      servers: { detailViewSerial: targetSerial },
    } = this.props;

    return (
      <BrandPopup
        lang={lang}
        brandName={targetSerial?.brand?.name}
        breweryName={targetSerial?.brewLoc?.name}
        createDate={formatDate(targetSerial?.createdAt)}
        photo={targetSerial?.brand?.imageUrl}
        onBrandNameClick={() => this.handleBrandPopupBrandNameClick()}
        onBreweryNameClick={() => this.handleBrandPopupBreweryNameClick()}
        showBrandPopup={showBrandPopup}
        onClose={() => this.toggleBrandPopup()}
      />
    );
  }

  protected getSetToUnprocessableResolutionPopup(): JSX.Element {
    const { showSetToUnprocessable } = this.state;
    const {
      //servers: { detailViewSerial: targetSerial }, // detailViewAlert: alertInfo,
      apps: { currentLanguage: lang },
    } = this.props;
    /*let restSold: boolean = false;
    if (targetSerial) {
      if (targetSerial.restSoldAt) restSold = true;
    }*/
    //const restRecvAt = targetSerial?.restRecvAt;
    //console.log('distribution detail restRecvAt: ' + restRecvAt);
    const buttonProps = getAlertRevertButtonProps(EAlertStatus.UNPROCESSABLE, lang);
    /*if (isUserAdmin && alertInfo?.type === EAlert.QUALITY && !restSold) {
      return (
        <UnresolvedEditPopup
          lang={lang}
          //nextStatus={EAlertStatus.UNPROCESSABLE}
          showPopup={showSetToUnprocessable}
          registerButtonProps={buttonProps}
          restRecvAt={restRecvAt}
          onClose={() => this.toggleSetToUnprocessablePopup()}
          onCancelClick={() => this.toggleSetToUnprocessablePopup()}
          onRegisterClick={() => this.handleSetToUnprocessableRegistrationClick()}
          onRegisterSaleClick={(value: Date) =>
            this.handleSetToUnprocessableRegistrationSaleClick(value)
          }
        />
      );
    }*/
    return (
      <AlertResolutionPopup
        lang={lang}
        nextStatus={EAlertStatus.UNPROCESSABLE}
        showPopup={showSetToUnprocessable}
        registerButtonProps={buttonProps}
        onClose={() => this.toggleSetToUnprocessablePopup()}
        onCancelClick={() => this.toggleSetToUnprocessablePopup()}
        onRegisterClick={(assigneeName: string) =>
          this.handleSetToUnprocessableRegistrationClick(assigneeName)
        }
      />
    );
  }

  protected getAlertResolutionPopup(): JSX.Element {
    const { showAlertResolution } = this.state; //apps: {currentLanguage: lang },
    const {
      // servers: {detailViewAlert: alertInfo, user },
      apps: { currentLanguage: lang },
    } = this.props;
    //const userCategory = getUserCategory(user);
    //const nextAlertStatus = getNextStatus(alertInfo?.status);
    // const buttonProps2: Partial<ButtonProps> = {
    //       color: 'status2',
    //       children: i18n.chooseOption[lang],
    // };
    // const nextAlertStatus = getNextStatus(alertInfo?.status);
    // const buttonProps2 = {color: 'status2', children: 'select options' };
    //const buttonProps = getAlertResolutionButtonProps(alertInfo?.status, lang);
    //       const alertType = alertInfo?.type;

    //       if (EAlertStatus.UNPROCESSED === alertInfo?.status) {
    //   if (alertType == EAlert.SCAN_DATE) {
    //     if (isUserAdmin(user)) {
    //       return this.handleAlertResolutionRegistrationClick();
    //     } else {
    //       return this.handleProcessingStatusEdit();
    //     }
    //   } else {
    //         //return this.handleAlertResolutionRegistrationClick();
    //       }
    // } else {
    //         //return this.handleAlertResolutionRegistrationClick();
    //       }

    const buttonProps = getAlertRevertButtonProps(EAlertStatus.PROCESSED, lang);
    return (
      <AlertResolutionPopup
        lang={lang}
        nextStatus={EAlertStatus.PROCESSED}
        showPopup={showAlertResolution}
        registerButtonProps={buttonProps}
        onClose={() => this.toggleAlertResolutionPopup()}
        onCancelClick={() => this.toggleAlertResolutionPopup()}
        onRegisterClick={(assigneeName: string) =>
          this.handleSetToProcessedRegistrationClick(assigneeName)
        }
      />
    );
  }

  // Duval -06Dec21- Create revert button for alert
  /*protected getAlertRevertPopup(): JSX.Element {
    const {showAlertRevert} = this.state;
          const {
            apps: {currentLanguage: lang },
          servers: {detailViewAlert: alertInfo },
    } = this.props;
          const buttonProps = getAlertRevertButtonProps(alertInfo?.status, lang);

    const onRegisterClick = (assigneeName: string) =>
          this.handleAlertRevertRegistrationClick(assigneeName);

          return (
          <AlertResolutionPopup
            lang={lang}
            nextStatus={getPreviousStatus(alertInfo?.status)}
            showPopup={showAlertRevert}
            registerButtonProps={buttonProps}
            onClose={() => this.toggleAlertRevertPopup()}
            onCancelClick={() => this.toggleAlertRevertPopup()}
            onRegisterClick={onRegisterClick}
          />
          );
  }*/

  protected handleProcessingStatusEdit(assigneeName?: string) {
    this.setState({ assigneeName });
    this.toggleAlertResolutionPopup();
    this.toggleSerialInformationEditPopup();
  }

  protected getSerialInformationEditPopup(): JSX.Element {
    const { showSerialInformationEdit } = this.state;
    const {
      apps: { currentLanguage: lang },
      servers: {
        user,
        detailViewSerial: targetSerial,
        detailViewAlert: targetAlert,
        detailViewAlert: alertInfo,
      },
    } = this.props;

    const userCategory = getUserCategory(user);

    const {
      showChat,
      showRangeEdit,
      showScanApp,
      showShippingDestEdit,
      showSalesRecord,
    } = getAvailableAlertResolutions(userCategory, targetSerial, targetAlert);
    const { currentDistLoc, currentRestLoc } = getCurrentLocations(targetSerial);

    let distLocationItems: SelectBoxItem[];
    let restLocationItems: SelectBoxItem[];
    let distRangeItems: SelectBoxItem[];
    let restRangeItems: SelectBoxItem[];

    if (showShippingDestEdit) {
      distLocationItems =
        userCategory === ECategoryType.BREWERY &&
        currentDistLoc?.id &&
        this.locationList
          ?.filter((loc) => loc.category === ECategoryType.DISTRIBUTOR)
          .map((loc) => ({ id: loc.id, text: loc.name }));

      // Sreenath - 19Nov21 - filtering out the inactive restaurants
      restLocationItems =
        (userCategory === ECategoryType.BREWERY || userCategory === ECategoryType.DISTRIBUTOR) &&
        currentRestLoc?.id &&
        this.locationList
          ?.filter((loc) => loc.category === ECategoryType.RESTAURANT && loc.status != 1)
          .map((loc) => ({ id: loc.id, text: loc.name }));
    }

    const restRecvAt = targetSerial?.restRecvAt;

    if (showRangeEdit) {
      const rangeItems: SelectBoxItem[] = [];
      let i = this.RangeIterator;
      while (i < this.RangeLimit) {
        rangeItems.push({ id: i, text: `${i}m` });
        i += this.RangeIterator;
      }

      distRangeItems = currentDistLoc?.id && rangeItems;
      restRangeItems = currentRestLoc?.id && rangeItems;
    }
    // Sreenath 30Nov21 - passing Optional attribute alertLocType to display the selectboxes accordingly
    return (
      <SerialInformationEditPopup
        lang={lang}
        showPopup={showSerialInformationEdit}
        onClose={() => this.toggleSerialInformationEditPopup()}
        onCancelClick={() => this.toggleSerialInformationEditPopup()}
        onScanAppClick={showScanApp && (() => this.openSakeApp())}
        onChatButtonClick={showChat && (() => this.handleChatButtonClick())}
        showShippingDestEdit={showShippingDestEdit}
        showSalesRecord={showSalesRecord}
        restRecvAt={restRecvAt}
        onLocationEditClick={(params: {
          selectedDistId?: number;
          selectedRestId?: number;
          slipNo?: string;
        }) => this.handleSerialInformationEditClick(params)}
        onRangeEditClick={(params: { selectedDistRange?: number; selectedRestRange?: number }) =>
          this.handleSerialRangeEditClick(params, currentDistLoc, currentRestLoc)
        }
        onRegisterSaleClick={(value: Date) => this.handleSetRegistrationSaleClick(value)}
        onRegisterAsInStockClick={() => this.handleSetRegisterAsInStockClick()}
        onCheckSerialCodeMatch={(scannedSerialCode: string) => {
          return this.handleCheckSerialCodeMatch(scannedSerialCode);
        }}
        onRegisterAsSoldClick={() => this.handleSetRegisterAsSoldClick()}
        onNotifyNotDeliveredClick={() => this.handleSetNotifyNotDeliveredClick()}
        onCancelDeliveryClick={() => this.handleSetCancelDeliveryClick()}
        onAdminSetResolvedClick={() => this.handleAdminSetResolvedClick()}
        onAdminSetUnresolvedClick={() => this.handleAdminSetUnresolvedClick()}
        onToggleSetToUnprocessablePopup={() => this.toggleSetToUnprocessablePopup()}
        onToggleAlertResolutionPopup={() => this.toggleAlertResolutionPopup()}
        distLocationItems={distLocationItems}
        restLocationItems={restLocationItems}
        distRangeItems={distRangeItems}
        restRangeItems={restRangeItems}
        selectedDistId={currentDistLoc?.id}
        selectedRestId={currentRestLoc?.id}
        selectedDistRange={currentDistLoc?.range}
        selectedRestRange={currentRestLoc?.range}
        alertLocType={alertInfo?.locType}
        alertType={alertInfo?.type}
        userCategory={userCategory}
        serialInfo={alertInfo?.serial}
      />
    );
  }

  protected handleSerialRangeEditClick(
    params: { selectedDistRange?: number; selectedRestRange?: number },
    distLocation?: ILocation,
    restLocation?: ILocation,
  ) {
    const {
      registerLocation,
      servers: { detailViewAlert: alertInfo },
    } = this.props;
    const { assigneeName } = this.state;

    // Update alert status
    this.executeRegisterAlertDetail(getNextStatus(alertInfo?.status), assigneeName);

    // Location Range Update
    if (distLocation && params?.selectedDistRange) {
      registerLocation({ ...distLocation, range: params?.selectedDistRange });
    }

    // Restaurant Range Update
    if (restLocation && params?.selectedRestRange) {
      registerLocation({ ...restLocation, range: params?.selectedRestRange });
    }

    // Clear assigneeName
    this.setState({ assigneeName: null });

    // Close popup
    this.toggleSerialInformationEditPopup();
  }

  protected async handleSerialInformationEditClick(params: {
    selectedDistId?: number;
    selectedRestId?: number;
    slipNo?: string;
  }) {
    const {
      registerAlertSerial,
      updateAlert,
      servers: { detailViewSerial: targetSerial, detailViewAlert: targetAlert },
    } = this.props;
    const { assigneeName } = this.state;
    const updateSerial: ISerial = { ...targetSerial };
    const selectedDistId = params?.selectedDistId;
    const selectedRestId = params?.selectedRestId;
    const slipNo = params?.slipNo;

    const alertCategory = getAlertCategory(targetSerial, targetAlert);
    const updateAlertRequest: IUpdateAlertRequest = {
      id: targetAlert?.id,
    };
    // Distributor Update
    if (selectedDistId) {
      updateSerial.brewShipDistLocId = selectedDistId;
      updateSerial.recvDistLocId = selectedDistId;
      updateSerial.shipDistLocId = selectedDistId;

      updateAlertRequest.distLocId = selectedDistId;

      if (alertCategory === ECategoryType.DISTRIBUTOR) {
        updateAlertRequest.alertLocId = selectedDistId;
      }
      if (slipNo) {
        if (targetAlert?.serial?.brewShipDistLocId && !targetAlert?.serial?.distShipRestLocId) {
          updateSerial.slip = slipNo;
        }
      } else {
        console.log('error, slipNo is needed for updating destination operation.');
      }
    }

    // Restaurant Update
    if (selectedRestId) {
      updateSerial.recvRestLocId = selectedRestId;

      // update slipNo when changing destination
      if (slipNo) {
        if (targetAlert?.serial?.brewShipRestLocId) {
          updateSerial.slip = slipNo;
        } else {
          updateSerial.distSlip = slipNo;
        }
      } else {
        console.log('error, slipNo is needed for updating destination operation.');
      }

      // Came from Distributor
      if (updateSerial.distShipRestLocId) {
        updateSerial.distShipRestLocId = selectedRestId;
      }

      // Came straight from Brewery
      if (updateSerial.brewShipRestLocId) {
        updateSerial.brewShipRestLocId = selectedRestId;
      }

      // Only when it's sold
      if (updateSerial.restSoldAt) {
        updateSerial.soldRestLocId = selectedRestId;
      }

      updateAlertRequest.restLocId = selectedRestId;

      if (alertCategory === ECategoryType.RESTAURANT) {
        updateAlertRequest.alertLocId = selectedRestId;
      }

      // make the alert status Resolved when updating the shipping destination to resturant
    }
    this.executeRegisterAlertDetail(
      EAlertStatus.UNPROCESSED,
      `Updated shipping destination and slip number.`,
    );

    // Update alert location info
    await updateAlert(updateAlertRequest);

    // Update base serial information
    await registerAlertSerial(updateSerial);

    // If there is an assigneeName in state, that means is 未対応 -> 対応中
    if (isOk(assigneeName)) {
      this.executeRegisterAlertDetail(EAlertStatus.PROCESSED, assigneeName);
      this.setState({ assigneeName: null });
    }

    this.toggleSerialInformationEditPopup();
  }

  protected openSakeApp() {
    // this.updateToNextStatus();
    this.toggleSerialInformationEditPopup();
    // window.open(userEnv.appUrl, hash('new_tab'));
    const {
      servers: { user },
    } = this.props;

    const appUrl = userEnv.appUrl;
    const uid = user.uid;
    const ts = Number(Date.now()) + userEnv.cryptoKey;
    window.open(appUrl + `/?uid=${uid}_${ts.toString()}`, '_self');
  }

  protected handleChatButtonClick() {
    this.updateToNextStatus();
    this.toggleSerialInformationEditPopup();
    this.gotoChatPage();
  }

  protected gotoChatPage() {
    const { history } = this.props;
    return history.push(`${AppRoutePaths['chat'].link}/`);
  }

  protected updateToNextStatus() {
    const {
      servers: { detailViewAlert: alertInfo },
    } = this.props;
    const { assigneeName } = this.state;
    this.executeRegisterAlertDetail(getNextStatus(alertInfo?.status), assigneeName);
  }

  protected handleSetToUnprocessableRegistrationClick(assigneeName?: string) {
    this.toggleSerialInformationEditPopup();
    this.executeRegisterAlertDetail(EAlertStatus.UNPROCESSABLE, assigneeName);
    this.setState({ assigneeName: null });
    this.toggleSetToUnprocessablePopup();
  }
  protected handleSetToProcessedRegistrationClick(assigneeName?: string) {
    this.toggleSerialInformationEditPopup();
    this.executeRegisterAlertDetail(EAlertStatus.PROCESSED, assigneeName);
    this.setState({ assigneeName: null });
    this.toggleAlertResolutionPopup();
  }

  protected skipToUnprocessableRegistrationClick(assigneeName?: string) {
    this.executeRegisterAlertDetail(EAlertStatus.UNPROCESSABLE, assigneeName);
    this.setState({ assigneeName: null });
  }

  protected handleSetRegistrationSaleClick(value?: Date) {
    //this.executeRegisterAlertDetail(EAlertStatus.UNPROCESSABLE, 'Sales recorded by Admin');
    //this.setState({ assigneeName: null });
    this.toggleSerialInformationEditPopup();
    this.executeRegisterSale(value);
  }

  protected handleCheckSerialCodeMatch(scannedSerialCode: string) {
    const {
      servers: { detailViewSerial: targetSerial },
    } = this.props;
    return targetSerial.code == scannedSerialCode;
  }

  protected handleSetRegisterAsInStockClick() {
    this.toggleSerialInformationEditPopup();
    this.executeRegisterInStock();
  }
  protected handleSetNotifyNotDeliveredClick() {
    this.toggleSerialInformationEditPopup();
    this.executeNotifyNotDelivered();
  }
  protected handleSetCancelDeliveryClick() {
    this.toggleSerialInformationEditPopup();
    this.executeCancelDelivery();
    const { history } = this.props;
    return history.push(`${AppRoutePaths['monitoring'].link}/`);
  }
  protected handleSetRegisterAsSoldClick() {
    this.toggleSerialInformationEditPopup();
    this.executeRegisterSold();
  }
  protected handleAdminSetResolvedClick() {
    this.toggleSerialInformationEditPopup();
    this.executeRegisterAlertDetail(EAlertStatus.PROCESSED);
  }
  protected handleAdminSetUnresolvedClick() {
    this.toggleSerialInformationEditPopup();
    this.executeRegisterAlertDetail(EAlertStatus.UNPROCESSABLE);
  }

  protected async executeRegisterInStock() {
    const {
      servers: { detailViewSerial: targetSerial },
      // registerSerials,
    } = this.props;
    const { targetSerialId } = this.state;
    const toRegisterRestaurantStates = {
      [EQROperations.RECEIVING]: [],
    };
    toRegisterRestaurantStates[EQROperations.RECEIVING].push({
      id: targetSerial.id,
      code: targetSerial.code,
      brandId: targetSerial.brandId,
    });
    //console.log(`register: ${JSON.stringify(toRegisterRestaurantStates[EQROperations.SELLING])}`);
    try {
      if (isOk(toRegisterRestaurantStates[EQROperations.RECEIVING])) {
        // await registerSerials({
        //   action: SERIAL_ACTION.receive,
        //   serials: toRegisterRestaurantStates[EQROperations.RECEIVING],
        // });
        this.executeRegisterAlertDetail(EAlertStatus.PROCESSED, 'Reported as in-stock.');
        if (targetSerialId) await getDetailViewSerial(targetSerialId);
      }
    } catch (response) {
      console.log('error:', response);
    }
  }
  protected async executeCancelDelivery() {
    const {
      servers: { detailViewSerial: targetSerial },
      registerSerialsCancelShipping,
    } = this.props;
    const { targetSerialId } = this.state;
    const toRegisterRestaurantStates = {
      [EQROperations.CANCEL_SHIPPING]: [],
    };
    toRegisterRestaurantStates[EQROperations.CANCEL_SHIPPING].push({
      id: targetSerial.id,
      code: targetSerial.code,
      brandId: targetSerial.brandId,
    });
    //console.log(`register: ${JSON.stringify(toRegisterRestaurantStates[EQROperations.SELLING])}`);
    try {
      if (isOk(toRegisterRestaurantStates[EQROperations.CANCEL_SHIPPING])) {
        await registerSerialsCancelShipping({
          action: SERIAL_ACTION.cancel,
          serials: toRegisterRestaurantStates[EQROperations.CANCEL_SHIPPING],
        });
        if (targetSerialId) await getDetailViewSerial(targetSerialId);
      }
    } catch (response) {
      console.log('error:', response);
    }
  }
  protected async executeNotifyNotDelivered() {
    const {
      servers: { detailViewAlert: alertInfo },
    } = this.props;

    try {
      this.executeRegisterAlertDetail(
        alertInfo?.status,
        this.AlertLocation?.name + ' reported that this product had not been delivered yet.',
      );
      this.executeSendNotification(ENotificationType.NOT_DELIVERED);
    } catch (response) {
      console.log('error:', response);
    }
  }
  //async
  protected async executeRegisterSold() {
    const {
      servers: { detailViewSerial: targetSerial, user, detailViewAlert: alertInfo },
      registerSerials,
    } = this.props;
    const { targetSerialId, targetAlertId } = this.state;
    const toRegisterRestaurantStates = {
      [EQROperations.SELLING]: [],
    };
    const alertType = alertInfo.type;
    const value = new Date(); // get today/now
    const soldAt = value.toISOString();
    const soldLoc = targetSerial.distShipRestLoc || targetSerial.brewShipRestLocId;
    toRegisterRestaurantStates[EQROperations.SELLING].push({
      id: targetSerial.id,
      code: targetSerial.code,
      soldRestLocId: soldLoc,
      recvRestLocId: soldLoc,
      restRecvAt: targetSerial.restRecvAt || soldAt,
      restSoldAt: soldAt,
      restSoldScanLatLng: targetSerial.restRecvScanLatLng,
      restSoldWarning: false,
      requireSurveyForRestSold: false,
      restSoldUserId: user.id,
      brandId: targetSerial.brandId,
    });
    //console.log(`register: ${JSON.stringify(toRegisterRestaurantStates[EQROperations.SELLING])}`);
    try {
      if (isOk(toRegisterRestaurantStates[EQROperations.SELLING])) {
        this.executeRegisterAlertDetail(EAlertStatus.UNPROCESSABLE);
        await registerSerials({
          action: SERIAL_ACTION.sell,
          geocode: targetSerial.restRecvScanLatLng,
          serials: toRegisterRestaurantStates[EQROperations.SELLING],
        });
        if (alertType == 1) {
          this.executeSendNotification(ENotificationType.SOLD);
        } else if (alertType == 3) {
          this.executeSendNotification(ENotificationType.SOLD_QUALITY);
        }

        targetSerial.restSoldAt = soldAt;
        if (targetSerialId) await getDetailViewSerial(targetSerialId);
        if (targetAlertId) await getDetailViewAlert(targetAlertId);
        this.executeRegisterAlertDetail(EAlertStatus.UNPROCESSABLE, `Reported as sold`);
      }
    } catch (response) {
      console.log('error:', response);
    }
  }
  //async
  protected async executeRegisterSale(value?: Date) {
    const {
      servers: { detailViewSerial: targetSerial, user },
      registerSerials,
    } = this.props;
    const { targetSerialId, targetAlertId } = this.state;
    const toRegisterRestaurantStates = {
      [EQROperations.SELLING]: [],
    };
    const soldAt = value.toISOString();
    toRegisterRestaurantStates[EQROperations.SELLING].push({
      id: targetSerial.id,
      code: targetSerial.code,
      soldRestLocId:
        targetSerial.recvRestLoc || targetSerial.distShipRestLoc || targetSerial.brewShipRestLoc,
      recvRestLocId:
        targetSerial.recvRestLoc || targetSerial.distShipRestLoc || targetSerial.brewShipRestLoc,
      restSoldAt: soldAt,
      restRecvAt: targetSerial.restRecvAt || soldAt,
      restSoldScanLatLng: targetSerial.restRecvScanLatLng,
      restSoldWarning: false,
      requireSurveyForRestSold: false,
      restSoldUserId: user.id,
      brandId: targetSerial.brandId,
    });
    //console.log(`register: ${JSON.stringify(toRegisterRestaurantStates[EQROperations.SELLING])}`);
    try {
      if (isOk(toRegisterRestaurantStates[EQROperations.SELLING])) {
        this.executeRegisterAlertDetail(EAlertStatus.PROCESSED);
        await registerSerials({
          action: SERIAL_ACTION.sell,
          geocode: targetSerial.restRecvScanLatLng,
          serials: toRegisterRestaurantStates[EQROperations.SELLING],
        });
        targetSerial.restSoldAt = soldAt;
        if (!targetSerial.restRecvAt) targetSerial.restRecvAt = soldAt;
        if (targetSerialId) await getDetailViewSerial(targetSerialId);
        if (targetAlertId) await getDetailViewAlert(targetAlertId);
      }
    } catch (response) {
      console.log('error:', response);
    }
  }

  protected handleAlertResolutionRegistrationClick(assigneeName?: string) {
    // const {
    //   servers: { detailViewAlert: alertInfo },
    // } = this.props;
    this.executeRegisterAlertDetail(EAlertStatus.UNPROCESSED, null);
    // this.executeRegisterAlertDetail(getNextStatus(alertInfo?.status), null);
    //this.setState({assigneeName: null });
    //this.toggleAlertResolutionPopup();
  }

  protected executeSendNotification(type: number) {
    const {
      sendNotification,
      servers: { user, detailViewAlert: alertInfo },
    } = this.props;
    sendNotification({
      type,
      alertId: alertInfo?.id,
      fromUserId: user.id,
      toUserId: alertInfo?.distLocId ? alertInfo?.distLocId : alertInfo?.brewLocId,
      serial: alertInfo?.serial.id,
      alertType: alertInfo?.type,
    });
  }

  protected executeRegisterAlertDetail(status: EAlertStatus, assigneeName?: string) {
    const {
      registerAlertDetail,
      servers: { user, detailViewAlert: alertInfo },
    } = this.props;
    registerAlertDetail({
      status,
      alertId: alertInfo?.id,
      userId: user.id,
      ...(assigneeName && { assigneeName }),
    });
  }

  protected handleAlertRevertRegistrationClick(assigneeName?: string) {
    const {
      servers: { detailViewAlert: alertInfo },
    } = this.props;
    this.executeRegisterAlertDetail(getPreviousStatus(alertInfo?.status), assigneeName);
    this.setState({ assigneeName: null });
    this.toggleAlertRevertPopup();
  }

  protected handleBrandPopupBrandNameClick() {
    const {
      servers: { detailViewSerial: targetSerial },
      setEditBrand,
      openBrandEditDialog,
    } = this.props;
    setEditBrand(targetSerial.brand);
    openBrandEditDialog(targetSerial.brand, 'monitoring');
  }

  protected handleBrandPopupBreweryNameClick() {
    const {
      servers: { detailViewSerial: targetSerial },
      setEditLocation,
      openLocationEditDialog,
    } = this.props;
    setEditLocation(targetSerial.brewLoc);
    openLocationEditDialog(targetSerial.brewLoc, 'monitoring');
  }

  protected getDistributionInfo(): JSX.Element {
    if (!isOk(this.props.servers?.detailViewSerial)) return;
    return (
      <>
        {this.getBreweryDetail()}
        {this.getDistributionDetail()}
        {this.getRestaurantDetail()}
        {/*this.getConsumerDetail()*/}
      </>
    );
  }

  protected getDistributionMap(): JSX.Element {
    const { showMapPopup } = this.state;
    if (!showMapPopup) return;
    const { googleMapApiKey } = userEnv;
    const {
      apps: { currentLanguage: lang },
      servers: { detailViewSerial: targetSerial },
    } = this.props;

    const brewLat = targetSerial?.brewLoc?.latitude;
    const brewLng = targetSerial?.brewLoc?.longitude;
    const brewName = targetSerial?.brewLoc?.name;
    const brewShipAt = targetSerial?.brewShipAt;

    const distLat = targetSerial?.recvDistLoc?.latitude;
    const distLng = targetSerial?.recvDistLoc?.longitude;
    const distName = targetSerial?.recvDistLoc?.name;
    const distRecvAt = targetSerial?.distRecvAt;

    const restLat = targetSerial?.recvRestLoc?.latitude;
    const restLng = targetSerial?.recvRestLoc?.longitude;
    const restName = targetSerial?.recvRestLoc?.name;
    const restRecvAt = targetSerial?.restRecvAt;

    const coordinates: MapViewCoordinate[] = [];

    if (brewLat && brewLng)
      coordinates.push({
        coord: { lat: brewLat, lng: brewLng },
        icon: 'brewery',
        label: brewName,
        secondLabel: formatDate(brewShipAt, 'MM/DD'),
      });
    if (distLat && distLng)
      coordinates.push({
        coord: { lat: distLat, lng: distLng },
        icon: 'distributor',
        label: distName,
        secondLabel: formatDate(distRecvAt, 'MM/DD'),
      });
    if (restLat && restLng)
      coordinates.push({
        coord: { lat: restLat, lng: restLng },
        icon: 'restaurant',
        label: restName,
        secondLabel: formatDate(restRecvAt, 'MM/DD'),
      });

    return (
      <MapView
        isModal
        showPopup={showMapPopup}
        onClose={() => this.toggleMapPopup()}
        lang={lang}
        apiKey={googleMapApiKey}
        coordinates={coordinates}
      />
    );
  }

  protected getDistributionSummary(): JSX.Element {
    const {
      servers: { detailViewSerial: targetSerial, detailViewAlert: alertInfo },
    } = this.props;
    if (!targetSerial) return;
    const { lang } = this.state;
    const {
      brand: { name: brandName },
      code,
      brewShipAt,
    } = targetSerial;

    return (
      <ListPageItem
        title={brandName}
        alert={getAlertMessage(isOk(alertInfo) && alertInfo?.type, lang)}
        subInfos={[
          { label: i18n.serialNo[lang], value: code },
          { label: i18n.brewShipmentDate[lang], value: formatDate(brewShipAt) },
        ]}
        endIcon={
          <Icon
            type='bottle'
            color='primary'
            size='large'
            isCircular={true}
            onClick={() => this.toggleBrandPopup()}
          />
        }
      />
    );
  }

  protected getAlertSummary(): JSX.Element {
    const {
      servers: { detailViewSerial: targetSerial, detailViewAlert: alertInfo, locations },
      apps: { currentLanguage: lang },
    } = this.props;
    let quality_alert_duration;
    const location_details = locations?.find((value) => {
      if (value.id == alertInfo?.alertLocId) {
        return value.quality_alert_duration;
      }
    });
    if (location_details && location_details.quality_alert_duration) {
      quality_alert_duration = location_details.quality_alert_duration;
    } else quality_alert_duration = '';

    const data: DistributionAlertSummaryProps = {
      lang,
      name: this.AlertLocation?.name,
      country: getCountryName(this.AlertLocation?.country, lang),
      message: getAlertMessage(alertInfo?.type, lang, quality_alert_duration),
      serial: Number(targetSerial?.code),
      alertAt: formatDate(alertInfo?.alertAt),
      // alertResolutionButton: this.getAlertResolutionButton(),
    };
    return <DistributionAlertSummary {...data} />;
  }

  protected toggleUpdateHistoryPopup() {
    const { showUpdateHistoryPopup } = this.state;
    this.setState({ showUpdateHistoryPopup: !showUpdateHistoryPopup });
  }

  protected toggleBrandPopup() {
    const { showBrandPopup } = this.state;
    this.setState({ showBrandPopup: !showBrandPopup });
  }

  protected toggleMapPopup() {
    const { showMapPopup } = this.state;
    this.setState({ showMapPopup: !showMapPopup });
  }

  protected toggleAlertResolutionPopup() {
    const { showAlertResolution } = this.state;
    this.setState({ showAlertResolution: !showAlertResolution });
    // this.toggleSerialInformationEditPopup();
  }

  // Duval -06Dec21- Create revert button for alert
  protected toggleAlertRevertPopup() {
    const { showAlertRevert } = this.state;
    this.setState({ showAlertRevert: !showAlertRevert });
    // this.toggleSerialInformationEditPopup();
  }

  protected toggleSetToUnprocessablePopup() {
    const { showSetToUnprocessable } = this.state;
    this.setState({ showSetToUnprocessable: !showSetToUnprocessable });
    // this.toggleSerialInformationEditPopup();
  }

  protected toggleSerialInformationEditPopup() {
    const { showSerialInformationEdit } = this.state;
    this.setState({ showSerialInformationEdit: !showSerialInformationEdit });
  }

  protected getTitle(): JSX.Element {
    return (
      <TextDisplay align='center' size='large' style={{ padding: '40px 0 16px' }}>
        {i18n.distributionInfo[this.state.lang]}
      </TextDisplay>
    );
  }

  protected getBreweryDetail(): JSX.Element {
    /*
          1 > brewLocId
            > brewShipAt
            > brewShipUserId
          */
    const {
      servers: { detailViewSerial: targetSerial },
    } = this.props;
    const { lang } = this.state;
    const locationName = targetSerial?.brewLoc?.name;
    const locationId = targetSerial?.brewLocId;
    const shippedAt = formatDate(targetSerial?.brewShipAt);

    if (!locationName || !locationId) return;

    const cardProps: DistributionCardProps = {
      label: locationName,
      cardData: [
        // TODO [SAKE-BC] Add TEMP field
        { label: i18n.shipmentDateShort[lang], value: shippedAt || '-' },
        { label: i18n.slipNo[lang], value: targetSerial?.slip || '-' },
      ],
    };

    const detailProps: DistributionDetailProps = null;

    return (
      <div>
        <DistributionCard {...cardProps} />
        <DistributionDetailVisual {...detailProps} />
      </div>
    );
  }

  protected getDistributionDetail(): JSX.Element {
    /*
          2 > recvDistLocId | brewShipDistLocId
            > distSlip
            > distRecvAt
            > distRecvUserId
            > distShipAt
            > distShipUserId
          */
    const {
      servers: { detailViewSerial: targetSerial, detailViewAlert: alertInfo },
    } = this.props;
    const { lang } = this.state;
    const distSlip = targetSerial?.distSlip;
    const location = targetSerial?.recvDistLoc || targetSerial?.brewShipDistLoc;
    const receivedAt = formatDate(targetSerial?.distRecvAt);
    const shippedAt = formatDate(targetSerial?.distShipAt);

    if (!isOk(location)) return;

    const receiveAlert: string =
      alertInfo?.alertLocId === location?.id &&
      (alertInfo?.type === EAlert.ILLEGAL_ROUTE || alertInfo?.type === EAlert.SCAN_DATE) &&
      getAlertMessage(alertInfo?.type, lang);

    const shippingAlert: string =
      alertInfo?.alertLocId === location?.id &&
      alertInfo?.type === EAlert.ILLEGAL_SHIPPING &&
      getAlertMessage(alertInfo?.type, lang);

    const durationAlert: string =
      alertInfo?.alertLocId === location?.id &&
      (alertInfo?.type === EAlert.DIST_QUALITY || alertInfo?.type === EAlert.QUALITY) &&
      getAlertMessage(alertInfo?.type, lang);

    if (receiveAlert || shippingAlert || durationAlert) {
      this.AlertLocation = location;
    }

    const duration = receivedAt && shippedAt && getDateDuration(receivedAt, shippedAt);

    const cardProps: DistributionCardProps = {
      label: location?.name,
      alertStatus:
        receiveAlert || shippingAlert || durationAlert
          ? getAlertStatusName(alertInfo?.status)
          : null,
      cardData: [
        // TODO [SAKE-BC] Add TEMP field
        {
          label: i18n.receiveDateShort[lang],
          value: receivedAt || '-',
          alertMessage: receiveAlert,
        },
        {
          label: i18n.storagePeriod[lang],
          value: duration || '-',
          alertMessage: durationAlert,
        },
        {
          label: i18n.shipmentDateShort[lang],
          value: shippedAt || '-',
          alertMessage: shippingAlert,
        },
        { label: i18n.slipNo[lang], value: distSlip || '-' },
      ],
    };

    return (
      <div>
        <DistributionCard {...cardProps} />
        <DistributionDetailVisual />
      </div>
    );
  }

  protected getRestaurantDetail(): JSX.Element {
    /*
          3 > recvRestLocId | brewShipRestLocId | distShipRestLocId
            > restRecvAt
            > restRecvUserId
            > restSoldAt 販売日時
            > restSoldUserId
          */
    const {
      servers: { detailViewSerial: targetSerial, detailViewAlert: alertInfo, locations },
      classes,
    } = this.props;
    const { lang } = this.state;
    let location;
    if (alertInfo?.type == 3) {
      // location = targetSerial?.recvDistLoc || targetSerial?.brewShipDistLoc;
      location = undefined;

      if (location == undefined) {
        location =
          targetSerial?.recvRestLoc ||
          targetSerial?.brewShipRestLoc ||
          targetSerial?.distShipRestLoc;
      }
    } else {
      location =
        targetSerial?.recvRestLoc || targetSerial?.brewShipRestLoc || targetSerial?.distShipRestLoc;
    }
    const receivedAt = formatDate(targetSerial?.restRecvAt) || formatDate(targetSerial?.restSoldAt);
    console.log(receivedAt);
    const soldAt = formatDate(targetSerial?.restSoldAt);
    let quality_alert_duration;
    const location_details = locations?.find((value) => {
      if (value.id == location?.id) {
        return value.quality_alert_duration;
      }
    });
    if (location_details && location_details.quality_alert_duration) {
      quality_alert_duration = location_details.quality_alert_duration;
    } else quality_alert_duration = '';

    if (!isOk(location)) return;

    const receiveAlert =
      alertInfo?.alertLocId === location?.id &&
      (alertInfo?.type === EAlert.ILLEGAL_ROUTE || alertInfo?.type === EAlert.SCAN_DATE) &&
      getAlertMessage(alertInfo?.type, lang);

    const sellingAlert =
      alertInfo?.alertLocId === location?.id &&
      alertInfo?.type === EAlert.ILLEGAL_SELLING &&
      getAlertMessage(alertInfo?.type, lang);

    const durationAlert: string =
      alertInfo?.alertLocId === location?.id &&
      alertInfo?.type === EAlert.QUALITY &&
      getAlertMessage(alertInfo?.type, lang, quality_alert_duration);

    if (receiveAlert || durationAlert || sellingAlert) {
      this.AlertLocation = location;
    }
    const duration = getDateDuration(receivedAt, soldAt);
    const cardProps: DistributionCardProps = {
      label: location?.name,
      alertStatus:
        receiveAlert || durationAlert || sellingAlert
          ? getAlertStatusName(alertInfo?.status)
          : null,
      cardData: [
        // TODO [SAKE-BC] Add TEMP field
        {
          label: i18n.receiveDateShort[lang],
          value: receivedAt || '-',
          alertMessage: receiveAlert,
        },
        {
          label: i18n.storagePeriod[lang],
          value: duration || '-',
          alertMessage: durationAlert,
        },
        {
          label: i18n.sellDateShort[lang],
          value: soldAt || '-',
          alertMessage: sellingAlert,
        },
      ],
    };

    return (
      <div className={classes.bottomCard}>
        <DistributionCard {...cardProps} />
      </div>
    );
  }
  // Duval -11Mar22- remove consumer details
  //protected getConsumerDetail(): JSX.Element {
  /*
          4 > restSoldAt
         */
  /*  const {
      servers: { detailViewSerial: targetSerial, detailViewAlert: alertInfo },
      classes,
    } = this.props;
    const { lang } = this.state;

    if (!targetSerial?.restSoldAt) return;

    const consumerAlert: string =
      alertInfo?.type === EAlert.CONSUMER_LOCATION && getAlertMessage(alertInfo?.type, lang);

    const cardProps: DistributionCardProps = {
      label: i18n.consumer[lang],
      mainAlertMessages: consumerAlert,
    };

    return (
      <div className={classes.bottomCard}>
        <DistributionCard {...cardProps} />
      </div>
    );
  }*/
}

export type Props = IStateProps & IDispatchProps;

export interface IStateProps {
  apps: IStateApps;
  servers: IStateServers;
  auth: IStateAuth;
  classes: any;
  caller?: ListPages;
  history?: History<LocationState>;
  showAlerts?: boolean;
}

export interface IDispatchProps {
  getDetailViewSerial: (serialId: number) => void;
  getDetailViewAlert: (alertId: number) => void;
  setEditBrand: (brandObj: IBrand) => void;
  openBrandEditDialog: (brandObj: IBrand, opener?: TBrandLocPopupOpener) => void;
  setEditLocation: (locationObj: ILocation) => void;
  openLocationEditDialog: (locationObj: ILocation, opener?: TBrandLocPopupOpener) => void;
  registerAlertSerial: (params: ISerial) => void;
  setCurrentPage: (pageId: AllPages) => void;
  registerAlertDetail: (params: {
    alertId: number;
    status: EAlertStatus;
    userId: number;
    assigneeName?: string;
  }) => void;
  sendNotification: (params: {
    alertId: number;
    type: number;
    fromUserId: number;
    toUserId: number;
    serial: number;
    alertType: number;
  }) => void;
  registerLocation: (params: Partial<ILocation>) => void;
  updateAlert: (params: IUpdateAlertRequest) => void;
  getMasterLocations: (query?: IQuery) => void;
  registerSerials: (serialObj: any, successMessage?: string) => void;
  registerSerialsCancelShipping: (serialObj: any, successMessage?: string) => void;
}

// Duval -06Dec21- Create revert button for alert
export interface State {
  lang: TSupportedLanguages;
  showBrandPopup?: boolean;
  showUpdateHistoryPopup?: boolean;
  showAlertResolution?: boolean;
  showAlertRevert?: boolean;
  showSetToUnprocessable?: boolean;
  showSerialInformationEdit?: boolean;
  showMapPopup?: boolean;
  targetSerialId?: number;
  targetAlertId?: number;
  assigneeName?: string;
  message: string;
  isMobile: boolean;
}

const mapStateToProps = (state: IStoreState): Partial<IStateProps> => ({
  apps: state.apps,
  servers: state.servers,
  auth: state.auth,
});

const mapDispatchToProps: IDispatchProps = {
  getDetailViewSerial,
  getDetailViewAlert,
  setEditBrand,
  openBrandEditDialog,
  setEditLocation,
  openLocationEditDialog,
  sendNotification,
  registerAlertDetail,
  registerAlertSerial,
  registerLocation,
  setCurrentPage,
  updateAlert,
  getMasterLocations,
  registerSerials,
  registerSerialsCancelShipping,
};

const myStyles = (theme: Theme): StyleRules => ({
  toolbar: { paddingTop: '68px' },
  container: { margin: 'auto', maxWidth: '90%' },
  bottomCard: { paddingBottom: '20px' },
  alertResolutionButtons: {
    paddingTop: '10px',
    paddingBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  updateHistory: {
    padding: '20px',
  },
  setToUnprocessableButton: {
    paddingTop: '10px',
  },
  chatIcon: {
    paddingBottom: '10px',
  },
  bottomAppBar: {
    //top: 'calc(100% - 61px)',
    //minHeight: bottomAppBarMinHeight,
    backgroundColor: '#fff',
  },
  bottomChatBar: {
    width: '100%',
  },
  commentTable: {
    padding: '5px',
  },
  nameCommentTable: {
    width: '15%',
    padding: '5px',
  },
  avatarCommentTable: {
    maxWidth: '5%',
    padding: '5px',
  },
  commentCommentTable: {
    width: '100%',
    padding: '5px',
  },
});

export const DistributionDetail = compose<any>(
  withStyles(myStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(DistributionDetailClass));
