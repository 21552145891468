/*
 * appsActions
 */
export const INITIALIZE_APPS = 'INITIALIZE_APPS';
export const OPEN_SNACKBAR_MESSAGE = 'OPEN_SNACKBAR_MESSAGE';
export const CLOSE_SNACKBAR_MESSAGE = 'CLOSE_SNACKBAR_MESSAGE';
export const START_SETTING = 'START_SETTING';
export const DONE_SETTING = 'DONE_SETTING';
export const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';
export const SET_MONITORING_FILTER = 'SET_MONITORING_FILTER';
// - sections
export const OPEN_ORDER_OF_SECTIONS_DIALOG = 'OPEN_ORDER_OF_SECTIONS_DIALOG';
export const CLOSE_ORDER_OF_SECTIONS_DIALOG = 'CLOSE_ORDER_OF_SECTIONS_DIALOG';
export const SET_ORDER_OF_SECTIONS = 'SET_ORDER_OF_SECTIONS';
// - serial-dist-histories
export const SET_EDIT_SERIAL = 'SET_EDIT_SERIAL';
export const OPEN_SERIAL_EDIT_DIALOG = 'OPEN_SERIAL_EDIT_DIALOG';
export const CLOSE_SERIAL_EDIT_DIALOG = 'CLOSE_SERIAL_EDIT_DIALOG';
export const OPEN_GOOGLE_MAP_POLYLINE_DIALOG = 'OPEN_GOOGLE_MAP_POLYLINE_DIALOG';
export const CLOSE_GOOGLE_MAP_DIALOG = 'CLOSE_GOOGLE_MAP_DIALOG';
export const OPEN_SERIALS_COMMENTS_DIALOG = 'OPEN_SERIALS_COMMENTS_DIALOG';
export const CLOSE_SERIALS_COMMENTS_DIALOG = 'CLOSE_SERIALS_COMMENTS_DIALOG';
export const GET_DETAIL_VIEW_SERIAL = 'GET_DETAIL_VIEW_SERIAL';
export const GET_DETAIL_VIEW_ALERT = 'GET_DETAIL_VIEW_ALERT';
export const OPEN_SEARCH_FILTER_POPUP = 'OPEN_SEARCH_FILTER_POPUP';
export const SET_SEARCH_FILTER_SELECTION_VALUES = 'SET_SEARCH_FILTER_SELECTION_VALUES';
export const CLOSE_SEARCH_FILTER_POPUP = 'CLOSE_SEARCH_FILTER_POPUP';
export const GET_LOCATION_IMAGES = 'GET_LOCATION_IMAGES';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const GET_STOCKS = 'GET_STOCKS';

// - rest-ship-conf
export const SET_EDIT_HISTORY_SUMMARY = 'SET_EDIT_HISTORY_SUMMARY';
export const OPEN_HISTORY_SUMMARY_EDIT_DIALOG = 'OPEN_HISTORY_SUMMARY_EDIT_DIALOG';
export const CLOSE_HISTORY_SUMMARY_EDIT_DIALOG = 'CLOSE_HISTORY_SUMMARY_EDIT_DIALOG';
export const OPEN_HISTORY_SUMMARY_ACTIVITIES_DIALOG = 'OPEN_HISTORY_SUMMARY_ACTIVITIES_DIALOG';
export const CLOSE_HISTORY_SUMMARY_ACTIVITIES_DIALOG = 'CLOSE_HISTORY_SUMMARY_ACTIVITIES_DIALOG';
export const OPEN_HISTORY_SUMMARY_TRANSITION_DIALOG = 'OPEN_HISTORY_SUMMARY_TRANSITION_DIALOG';
export const CLOSE_HISTORY_SUMMARY_TRANSITION_DIALOG = 'CLOSE_HISTORY_SUMMARY_TRANSITION_DIALOG';
export const OPEN_HISTORY_SUMMARY_COMMENTS_DIALOG = 'OPEN_HISTORY_SUMMARY_COMMENTS_DIALOG';
export const CLOSE_HISTORY_SUMMARY_COMMENTS_DIALOG = 'CLOSE_HISTORY_SUMMARY_COMMENTS_DIALOG';
// - rest-management
export const SET_EDIT_REST_PRODUCT = 'SET_EDIT_REST_PRODUCT';
export const SET_SCAN_DUE_DATE = 'SET_SCAN_DUE_DATE';
export const SET_SCAN_QUALITY_DATE = 'SET_SCAN_QUALITY_DATE';
export const OPEN_REST_PRODUCT_EDIT_DIALOG = 'OPEN_REST_PRODUCT_EDIT_DIALOG';
export const CLOSE_REST_PRODUCT_EDIT_DIALOG = 'CLOSE_REST_PRODUCT_EDIT_DIALOG';
export const OPEN_REST_PRODUCT_ACTIVITIES_DIALOG = 'OPEN_REST_PRODUCT_ACTIVITIES_DIALOG';
export const CLOSE_REST_PRODUCT_ACTIVITIES_DIALOG = 'CLOSE_REST_PRODUCT_ACTIVITIES_DIALOG';
export const OPEN_REST_PRODUCT_TRANSITION_DIALOG = 'OPEN_REST_PRODUCT_TRANSITION_DIALOG';
export const CLOSE_REST_PRODUCT_TRANSITION_DIALOG = 'CLOSE_REST_PRODUCT_TRANSITION_DIALOG';
export const OPEN_REST_PRODUCT_COMMENTS_DIALOG = 'OPEN_REST_PRODUCT_COMMENTS_DIALOG';
export const CLOSE_REST_PRODUCT_COMMENTS_DIALOG = 'CLOSE_REST_PRODUCT_COMMENTS_DIALOG';
export const SET_SELECTED_SIDE_PANEL_OPTION_ID = 'SET_SELECTED_SIDE_PANEL_OPTION_ID';
// - consumer scan analyze
export const OPEN_SCAN_ANALYZE_COMMENTS_DIALOG = 'OPEN_SCAN_ANALYZE_COMMENTS_DIALOG';
export const CLOSE_SCAN_ANALYZE_COMMENTS_DIALOG = 'CLOSE_SCAN_ANALYZE_COMMENTS_DIALOG';
export const OPEN_SCAN_ANALYZE_BRANDS_TRANSITION_DIALOG =
  'OPEN_SCAN_ANALYZE_BRANDS_TRANSITION_DIALOG';
export const CLOSE_SCAN_ANALYZE_BRANDS_TRANSITION_DIALOG =
  'CLOSE_SCAN_ANALYZE_BRANDS_TRANSITION_DIALOG';
export const OPEN_SCAN_ANALYZE_COUNTRIES_TRANSITION_DIALOG =
  'OPEN_SCAN_ANALYZE_COUNTRIES_TRANSITION_DIALOG';
export const CLOSE_SCAN_ANALYZE_COUNTRIES_TRANSITION_DIALOG =
  'CLOSE_SCAN_ANALYZE_COUNTRIES_TRANSITION_DIALOG';
export const OPEN_SCAN_ANALYZE_CITIES_TRANSITION_DIALOG =
  'OPEN_SCAN_ANALYZE_CITIES_TRANSITION_DIALOG';
export const CLOSE_SCAN_ANALYZE_CITIES_TRANSITION_DIALOG =
  'CLOSE_SCAN_ANALYZE_CITIES_TRANSITION_DIALOG';
export const OPEN_SCAN_ANALYZE_AGES_TRANSITION_DIALOG = 'OPEN_SCAN_ANALYZE_AGES_TRANSITION_DIALOG';
export const CLOSE_SCAN_ANALYZE_AGES_TRANSITION_DIALOG =
  'CLOSE_SCAN_ANALYZE_AGES_TRANSITION_DIALOG';
export const OPEN_SCAN_ANALYZE_GENDERS_TRANSITION_DIALOG =
  'OPEN_SCAN_ANALYZE_GENDERS_TRANSITION_DIALOG';
export const CLOSE_SCAN_ANALYZE_GENDERS_TRANSITION_DIALOG =
  'CLOSE_SCAN_ANALYZE_GENDERS_TRANSITION_DIALOG';
export const OPEN_SCAN_ANALYZE_TIMEZONES_TRANSITION_DIALOG =
  'OPEN_SCAN_ANALYZE_TIMEZONES_TRANSITION_DIALOG';
export const CLOSE_SCAN_ANALYZE_TIMEZONES_TRANSITION_DIALOG =
  'CLOSE_SCAN_ANALYZE_TIMEZONES_TRANSITION_DIALOG';
// - consumer location analyze
export const OPEN_LOCATION_ANALYZE_COMMENTS_DIALOG = 'OPEN_LOCATION_ANALYZE_COMMENTS_DIALOG';
export const CLOSE_LOCATION_ANALYZE_COMMENTS_DIALOG = 'CLOSE_LOCATION_ANALYZE_COMMENTS_DIALOG';
// - locations
export const SET_EDIT_LOCATION = 'SET_EDIT_LOCATION';
export const OPEN_LOCATION_EDIT_DIALOG = 'OPEN_LOCATION_EDIT_DIALOG';
export const CLOSE_LOCATION_EDIT_DIALOG = 'CLOSE_LOCATION_EDIT_DIALOG';
// - brands
export const SET_EDIT_BRAND = 'SET_EDIT_BRAND';
export const OPEN_BRAND_EDIT_DIALOG = 'OPEN_BRAND_EDIT_DIALOG';
export const CLOSE_BRAND_EDIT_DIALOG = 'CLOSE_BRAND_EDIT_DIALOG';
// - users
export const SET_EDIT_USER = 'SET_EDIT_USER';
export const OPEN_USER_EDIT_DIALOG = 'OPEN_USER_EDIT_DIALOG';
export const CLOSE_USER_EDIT_DIALOG = 'CLOSE_USER_EDIT_DIALOG';

// test video call
export const OPEN_VIDEO_CALL_DIALOG = 'OPEN_VIDEO_CALL_DIALOG';
export const CLOSE_VIDEO_CALL_DIALOG = 'CLOSE_VIDEO_CALL_DIALOG';

/*
 * authActions
 */
export const INIT_AUTH_REQUEST = 'INIT_AUTH_REQUEST';
export const INIT_AUTH = 'INIT_AUTH';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_WITH_EMAIL = 'LOGIN_WITH_EMAIL';
export const LOGIN_WITH_TOKEN = 'LOGIN_WITH_TOKEN';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT = 'LOGOUT';
export const SET_USER_UID = 'SET_USER_UID';

/*
 * serversActions
 */
export const INITIALIZE_SERVERS = 'INITIALIZE_SERVERS';
export const SERVER_REQUEST = 'SERVER_REQUEST';
export const SERVER_GET_REQUEST = 'SERVER_GET_REQUEST';
export const SERVER_GET_AUTH_USER_REQUEST = 'SERVER_GET_AUTH_USER_REQUEST';
export const SERVER_ADDITIONAL_GET_REQUEST = 'SERVER_ADDITIONAL_GET_REQUEST';
export const SERVER_REQUEST_DONE = 'SERVER_REQUEST_DONE';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_ADDITIONAL_NOTIFICATIONS = 'GET_ADDITIONAL_NOTIFICATIONS';
export const GET_NEW_NOTIFICATION_COUNT = 'GET_NEW_NOTIFICATION_COUNT';
export const GET_SCAN_INTERVAL_TIME = 'GET_SCAN_INTERVAL_TIME';
export const SET_SCAN_INTERVAL_TIME = 'SET_SCAN_INTERVAL_TIME';
// - Section: serial-dist-histories
export const REGISTER_SERIAL = 'REGISTER_SERIAL';
export const REGISTER_SERIALS = 'REGISTER_SERIALS';
export const UPDATE_ALERT = 'UPDATE_ALERT';
export const REGISTER_ALERT_SERIAL = 'REGISTER_ALERT_SERIAL';
export const GET_SERIALS = 'GET_SERIALS';
export const GET_ADDITIONAL_SERIALS = 'GET_ADDITIONAL_SERIALS';
export const GET_ADDITIONAL_ALERTS = 'GET_ADDITIONAL_ALERTS';
export const DELETE_SERIAL = 'DELETE_SERIAL';
export const RESET_SERIAL = 'RESET_SERIAL';
export const SET_SERIALS_QUERY = 'SET_SERIALS_QUERY';
export const SET_ALERTS_QUERY = 'SET_ALERTS_QUERY';
export const GET_SERIAL_BRANDS = 'GET_SERIAL_BRANDS';
export const GET_SERIAL_BREW_LOCATIONS = 'GET_SERIAL_BREW_LOCATIONS';
export const GET_SERIAL_DIST_LOCATIONS = 'GET_SERIAL_DIST_LOCATIONS';
export const GET_SERIAL_REST_LOCATIONS = 'GET_SERIAL_REST_LOCATIONS';
export const GET_ALERTS = 'GET_ALERTS';
export const GET_ALERTS_COUNT = 'GET_ALERTS_COUNT';
export const GET_ALERTS_URGENT_COUNT = 'GET_ALERTS_URGENT_COUNT';
export const GET_FILTER_ALERTS_COUNT = 'GET_FILTER_ALERTS_COUNT';

export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const REGISTER_ALERT_DETAIL = 'REGISTER_ALERT_DETAIL';
// - Master: locations
export const ACTIVATE_LOCATION = 'ACTIVATE_LOCATION';
export const DEACTIVATE_LOCATION = 'DEACTIVATE_LOCATION';
export const REJECT_LOCATION = 'REJECT_LOCATION';
export const REGISTER_LOCATION = 'REGISTER_LOCATION';
export const REGISTER_LOCATION_IMAGE = 'REGISTER_LOCATION_IMAGE';
export const DELETE_LOCATION_IMAGE = 'DELETE_LOCATION_IMAGE';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_MASTER_LOCATIONS = 'GET_MASTER_LOCATIONS';
export const GET_ALL_LOCATIONS = 'GET_ALL_LOCATIONS';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const SET_LOCATIONS_QUERY = 'SET_LOCATIONS_QUERY';
export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_LOCATION_REGIONS = 'GET_LOCATION_REGIONS';
// - Master: brands
export const REGISTER_BRAND = 'REGISTER_BRAND';
export const GET_BRANDS = 'GET_BRANDS';
export const GET_MASTER_BRANDS = 'GET_MASTER_BRANDS';
export const GET_ALL_BRANDS = 'GET_ALL_BRANDS';
export const DELETE_BRAND = 'DELETE_BRAND';
export const SET_BRANDS_QUERY = 'SET_BRANDS_QUERY';
export const GET_BRAND_LOCATIONS = 'GET_BRAND_LOCATIONS';
export const SET_EDIT_LOCATION_IMAGES = 'SET_EDIT_LOCATION_IMAGES';
export const DELETE_EDIT_LOCATION_IMAGES = 'DELETE_EDIT_LOCATION_IMAGES';
export const SET_NOTIFICATION_QUERY = 'SET_NOTIFICATION_QUERY';
export const REGISTER_NOTIFICATION = 'REGISTER_NOTIFICATION';

// - Master: users
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_TEMP_USER = 'REGISTER_TEMP_USER';
export const REGISTER_LOGIN_HISTORY = 'REGISTER_LOGIN_HISTORY';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const GET_AUTH_USER = 'GET_AUTH_USER';
export const GET_USERS = 'GET_USERS';
export const GET_MASTER_USERS = 'GET_MASTER_USERS';
export const DELETE_USER = 'DELETE_USER';
export const SET_USERS_QUERY = 'SET_USERS_QUERY';
export const GET_USER_LOCATIONS = 'GET_USER_LOCATIONS';

// - Master: analysis
export const GET_ANALYSIS = 'GET_ANALYSIS';
export const GET_ALERT_ANALYSIS = 'GET_ALERT_ANALYSIS';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_INVENTORY = 'GET_INVENTORY';
export const SET_ANALYSIS_QUERY = 'SET_ANALYSIS_QUERY';

// - Section: history-summaries
export const REGISTER_HISTORY_SUMMARY = 'REGISTER_HISTORY_SUMMARY';
export const GET_HISTORY_SUMMARIES = 'GET_HISTORY_SUMMARIES';
export const DELETE_HISTORY_SUMMARY = 'DELETE_HISTORY_SUMMARY';
export const SET_HISTORY_SUMMARIES_QUERY = 'SET_HISTORY_SUMMARIES_QUERY';
// - Section: restaurant-product
export const REGISTER_RESTAURANT_PRODUCT = 'REGISTER_RESTAURANT_PRODUCT';
export const GET_RESTAURANT_PRODUCTS = 'GET_RESTAURANT_PRODUCTS';
export const GET_RESTAURANT_PRODUCTS_BY_ID = 'GET_RESTAURANT_PRODUCTS_BY_ID';
export const DELETE_RESTAURANT_PRODUCT = 'DELETE_RESTAURANT_PRODUCT';
export const SET_RESTAURANT_PRODUCTS_QUERY = 'SET_RESTAURANT_PRODUCTS_QUERY';
// - Section: activities
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const SET_ACTIVITIES_QUERY = 'SET_ACTIVITIES_QUERY';
// - Section: comments
export const REGISTER_COMMENT = 'REGISTER_COMMENT';
export const GET_COMMENTS = 'GET_COMMENTS';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const SET_COMMENTS_QUERY = 'SET_COMMENTS_QUERY';

// - Chat: users
export const GET_CHAT_USERS = 'GET_CHAT_USERS';
export const SET_CHAT_USERS_QUERY = 'SET_CHAT_USERS_QUERY';
// - Chat: allUserChats
export const REGISTER_CHAT_ROOM = 'REGISTER_CHAT_ROOM';
export const GET_CHAT_ROOMS = 'GET_CHAT_ROOMS';
export const DELETE_CHAT_ROOM = 'DELETE_CHAT_ROOM';
export const SET_CHAT_ROOMS_QUERY = 'SET_CHAT_ROOMS_QUERY';
export const GET_OR_CREATE_CHAT_ROOM = 'GET_OR_CREATE_CHAT_ROOM';
export const SET_CHAT_ROOM = 'SET_CHAT_ROOM';
// - Chat: chatMessages
export const REGISTER_CHAT_MESSAGE = 'REGISTER_CHAT_MESSAGE';
export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES';
export const DELETE_CHAT_MESSAGE = 'DELETE_CHAT_MESSAGE';
export const SET_CHAT_MESSAGES_QUERY = 'SET_CHAT_MESSAGES_QUERY';

// - Section: Google map api
export const GET_PLACE_AUTOCOMPLETE = 'GET_PLACE_AUTOCOMPLETE';
export const CLEAR_SUGGESTIONS_PLACES = 'CLEAR_SUGGESTIONS_PLACES';

// Restaurant Status Table
export const SET_EXPANDED_REST_STATUS_TABLE_NESTED_TABLE =
  'SET_EXPANDED_REST_STATUS_TABLE_NESTED_TABLE';

export const SET_ALERT_FILTER_QUERY = 'SET_ALERT_FILTER_QUERY';

export const GEOCODE = 'GEOCODE';
export const CLEAR_GEOCODE = 'CLEAR_GEOCODE';
